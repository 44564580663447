import React from "react";

const sizes = {
  "2xl": "text-[26px] font-bold leading-[33px] md:text-2xl sm:text-[22px]",
  xl: "text-2xl font-bold leading-[31px] md:text-[22px]",
  s: "text-base font-semibold leading-[21px]",
  md: "text-xl font-bold leading-[26px]",
  xs: "text-sm font-bold leading-[18px]",
  lg: "text-[21px] font-bold leading-7",
};

export type HeadingProps = Partial<{
  className: string;
  as: any;
  size: keyof typeof sizes;
}> &
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;

const Heading: React.FC<React.PropsWithChildren<HeadingProps>> = ({
  children,
  className = "",
  size = "s",
  as,
  ...restProps
}) => {
  const Component = as || "h6";

  return (
    <Component className={`text-blue_gray-800_02 font-mulish ${className} ${sizes[size]}`} {...restProps}>
      {children}
    </Component>
  );
};

export { Heading };
