import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import CustomInput from "components/customizedInput";
import { Button, Img } from "components";
import { Link } from "react-router-dom";
import { dummyUser } from "dummyData";
import { useNavigate } from "react-router-dom";
import AuthLayout from "authlayout";
import { toast } from "react-toastify";
import { ApiService } from "axiosRequest";

const Login = () => {
  const [success, setSuccess] = useState(false);
  const history = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email("Invalid email address")
        .required("Email is required"),
      password: yup
        .string()
        .min(8, "Password must be at least 8 characters")
        .required("Password is required"),
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {

      const res = await new ApiService().login(values)
      // console.log(res)
     
        if (
          values.email == dummyUser.email &&
          values.password === dummyUser.password
        ) {
        
            setSuccess(true);
            toast.success("Login successful")

        } else {
          toast.error("Invalid login credentials");
        }
        resetForm()

  
    
    },
  });

  return (
    <AuthLayout>
      <form
        onSubmit={formik.handleSubmit}
        className="w-full h-full sm:p-6 md:p-3 p-5 flex flex-col justify-between "
      >
        <div className="w-full">
          <h1 className="sm:text-[1.5rem] text-[1.8rem] font-semibold mulish text-center">
            Welcome back
          </h1>

          <p className="sm:text-[12px] pb-4 text-center text-[14px]">
            Welcome back! Please enter your details
          </p>
        </div>

        <div className="w-full">
          <label htmlFor="email" className="block my-2 p2-4">
            Email
          </label>
          <CustomInput
            name="email"
            type="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            bgColor="bg-white"
            placeholder="Enter your email"
            error={formik.touched.email && formik.errors.email}
            icon="images/sms.png"
          />
        </div>

        <div className="w-full block my-2 p2-4">
          <label htmlFor="password" className="mb-2">
            Password
          </label>
          <CustomInput
            name="password"
            type="password"
            bgColor="bg-white"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            placeholder="Enter your password"
            error={formik.touched.password && formik.errors.password}
            icon="images/lock.png"
          />
        </div>

          <Button
            type="submit"
            color="blue_gray_800_02"
            size="4xl"
            variant="fill"
            shape="round"
            className="w-full bg-blue_gray-800_02 text-white rounded-md px-2 mt-4"
          >
            {formik.isSubmitting ? "wait ..." : "Sign In"}
          </Button>

          <div className="flex justify-between items-center gap-4 my-2 text-gray-500 text-sm">
            <div className="flex items-center">
              <input type="checkbox" className="rounded-[4px]" />{" "}
              <span className="ml-2">Remember me</span>
            </div>
            <Link to="/forgot" className="underline text-semibold ">
              Forgot Password?
            </Link>
          </div>
     

        <Button
          type="button"
          size="4xl"
          variant="fill"
          shape="round"
          className="w-full bg-blue_gray-800_02 text-white border-[1.2px] rounded-md mt-3 flex  sm:my-2"
        >
          <Img src="images/google.svg" />{" "}
          <span className="ml-2">Sign in with google</span>
        </Button>

        <p className="flex items-center justify-center py-3">
          <span className="pr-2">Don't have an account?</span>{" "}
          <Link to={"/signup"} className="font-bold text-gray-800">
            Sign Up
          </Link>
        </p>
      </form>
      {success && history("/dashboard")}
    </AuthLayout>
  );
};

export default Login;
