import create from 'zustand';

interface Organization {
        CO2_emission: string;
        activity: string;
        city: string;
        country: string;
        end_date: string;
        id: number;
        nb_employees: number;
        nb_entities: number;
        organization_name: string;
        start_date: string;
        status: boolean;
}

interface User {
  id: string;
  name: string;
}

interface IEntity{
    entityName:string;
    entityId:string,
    organizationId :string;
    id:number;
    nb_employees: number;
    nbLocal:number;
    activity:string;
    status:string
}
interface StoreState {
  organizations: Organization[];
  user: User;
  setOrganizations: (organizations: Organization[]) => void;
  setUser: (user: User) => void;
  setEntities : (entities: IEntity[]) => void;
  entities:IEntity[]
}

const useStore = create<StoreState>((set) => ({
  organizations: [],
  user: {} as any,
  setOrganizations: (organizations) => set({ organizations }),
  setUser: (user) => set({ user }),
  entities:[],
  setEntities:(entities)=>set({entities}),
}));

export default useStore;
