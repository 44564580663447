import { Button, Heading, Img } from "components";
import OtpInput from "components/otpComponent";
import React, { useState } from "react";
import { Link } from "react-router-dom";

function OtpScreen() {
  const [otp, setOtp] = useState<string[]>(Array(4).fill(""));

  return (
    <div className="w-full h-full sm:p-6 md:p-3 p-5 flex flex-col gap-2 items-center">
      <div className="self-center">
        <Img src="images/messageIcon.svg" alt="message_icon" className="mb-3" />
      </div>
      <h1 className="text-[2.1rem] font-bold self-center">Check your email</h1>
      <h3 className="px-2 sm:text-[14px] text-[16px] pb-4">
        We sent a 4-digit code to david@gmail.com
      </h3>
      <Button
        type="button"
        size="4xl"
        variant="fill"
        shape="round"
        color="blue_gray_800_02"
        className="w-full bg-blue_gray-800_02 text-white border-[1.2px] rounded-md mt-3 flex mr-2"
      >
        Open email app
      </Button>

      <OtpInput length={4} otp={otp} setOtp={setOtp} />

      <p className="flex items-center justify-center py-2">
        <span className="pr-2">Didn't get a code? </span>{" "}
        <Link to={"/auth/login"} className="font-bold text-gray-800 underline">
          Click to resend
        </Link>
      </p>

      <Link
        to="/login"
        className="w-full border-[1.2px] rounded-md p-3 mt-3 flex mr-2 flex justify-center items-center"
      >
        <Img src="images/arrow-left.svg" className="h-8 w-8 mr-2 " />{" "}
        <span>Bank to login</span>
      </Link>
    </div>
  );
}

export default OtpScreen;
