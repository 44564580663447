export const menuList: { to: string; src: string; title: string }[] = [
  {
    to: "entities",
    src: "images/img_home_2.svg",
    title: "Entities",
  },
  {
    to: "users",
    src: "images/img_people.svg",
    title: "Users",
  },
  {
    to: "sensors",
    src: "images/img_driver_2.svg",
    title: "Sensors",
  },
  {
    to: "settings",
    src: "images/img_setting_2.svg",
    title: "Settings",
  },

  {
    to: "operations",
    src: "images/img_shield_security.svg",
    title: "Operations",
  },

  {
    to: "emission",
    src: "images/img_book.svg",
    title: "Emission Calculations",
  },

  {
    to: "actions",
    src: "images/img_clipboard_tick.svg",
    title: "Actions",
  },
];

export const IMenusEnum= {
  Dashboard:"dashboard",
  Action: "actions",
  Emission: "emission",
  Operation: "operations",
  Entities: "entities",
  Users: "users",
  Sensor: "sensors",
  Setting: "settings",
}
