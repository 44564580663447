import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import CreateModal from "modals/customModal";
import CustomInput from "components/customizedInput";
import { Button } from "components/Button";
import { ApiService } from "axiosRequest";
import { toast } from "react-toastify";
import { SelectBox } from "components/SelectBox";
import useStore from "zustandState";

interface Props {
  className?: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;

}

const CreateSensorForm = ({ isOpen, setIsOpen, ...props }: Props) => {

  const entities = useStore((state)=>state.entities)
  const [selectedEntity, setSelectedEntity]= useState('')

  const entityDropdown = entities.map(item=>{
    return {value:item.id, label:item.entityName}
  })

 const handleSelectedEntity=(e)=>{
    setSelectedEntity(e.value)
  }

  const formik = useFormik({
    initialValues: {
      record: "",
      libelle: "",
      model: "",
      start_date:"",
      end_date:""
  
     
    },
    validationSchema: yup.object().shape({
      record: yup.string().required(),
      libelle: yup.string().required(),
      model: yup.string().required(),
      start_date:yup.date().required(),
      end_date:yup.date().required(),

    }),
    onSubmit: async (values, { setSubmitting }) => {
      
     const res = await new ApiService().addSensor({...values, sensor_id:"232838", local_id:"73", status:true, entity_id:selectedEntity.toString()})
     if(!res.error){
     setIsOpen(false)
     toast.success("Entity Added successfully")
     }
    },
  });

  const fields = [
    { type:'text', placeholder:`Enter Record`, label: "Record", name: "record" },
    { type:'text', placeholder:`Enter model`, label: "Model", name: "model" },
    { type:'text', placeholder:`Enter Label`, label: "Label", name: "libelle" },
    { type:'date', placeholder:`Enter Start Date`, label: "Start Date", name: "start_date" },
    { type:'date', placeholder:`Enter End Date`, label: "End Date", name: "end_date" },


   
  ];

  return (
    <CreateModal title="Create Sensor" isOpen={isOpen} setIsOpen={setIsOpen} {...props}>
      <form onSubmit={formik.handleSubmit} className="mt-4"> 
      <div className="my-2 ">
        <label className="py-2">Entity</label>
      <SelectBox className="border-[1.2px] rounded-md mt-2" options={entityDropdown} onChange={(e)=>handleSelectedEntity(e)}/>
      </div>
        {fields.map((field) => (
         <div className="my-6 ">
           <label>{field.label}</label>
          <CustomInput
            key={field.name}
            placeholder={field.placeholder}
            name={field.name}
            value={formik.values[field.name]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[field.name] && formik.errors[field.name]}
            type={field.type}

          />
         </div>
        ))}

        <Button
          color="blue_gray_800_02"
          size="4xl"
          variant="fill"
          shape="round"
          className="sm:px-5 font-bold min-w-[100%] self-center mt-4"
          disabled={formik.isSubmitting}
          type="submit"
        >
          {formik.isSubmitting ? "Saving..." : "Save Information"}
        </Button>
      </form>
    </CreateModal>
  );
};

export default CreateSensorForm;







