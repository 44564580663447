import React, { SetStateAction, useState } from "react";
import { Button } from "../../components";
import CreateModal from "modals/customModal";


import EditUserComponent from "components/editUser";

interface Props {
  className?: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<SetStateAction<boolean>>;
  data: IData;
}

interface IData {
  address: string;
  deplacement: string;
  email: string;
  entity_id: string;
  entity_name: string;
  id: number;
  name: string;
  organisation_name: string;
  role: string;
  statut: string;
  tel: string;
}

export default function ViewUser({
  isOpen,
  setIsOpen,
  data,
  ...props
}: Props) {
  const [edit, setEdit] = useState(false);

  if (!data) return;
  const {
    address,
    deplacement,
    email,
    entity_name,
    name,
    organisation_name,
    role,
    statut,
    tel,
  } = data;

  return (
    <CreateModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={name}
      img="images/lady.png"
    >
      {!edit ? (
        <>
 
          <div className="flex flex-col w-full gap-6 mb-3">
            <div className=" border-gray-200_01 border border-solid  p-[5px] rounded-md flex flex-col pl-4 pr-[35px] sm:pt-5 sm:pr-5 text-blue_gray-800_02 text-left text-base font-semibold bg-gray-50">
              <p className="py-2">Email</p>
              <p className="pb-2"> {email}</p>
            </div>
            <div className=" border-gray-200_01 border border-solid  p-[5px] rounded-md flex flex-col pl-4 pr-[35px] sm:pt-5 sm:pr-5 text-blue_gray-800_02 text-left text-base font-semibold bg-gray-50">
              <p className="py-2">Phone Number</p>
              <p className="pb-2"> {tel}</p>
            </div>
            <div className=" border-gray-200_01 border border-solid  p-[5px] rounded-md flex flex-col pl-4 pr-[35px] sm:pt-5 sm:pr-5 text-blue_gray-800_02 text-left text-base font-semibold bg-gray-50">
              <p className="py-2">Organization</p>
              <p className="pb-2"> {organisation_name}</p>
            </div>
            <div className=" border-gray-200_01 border border-solid  p-[5px] rounded-md flex flex-col pl-4 pr-[35px] sm:pt-5 sm:pr-5 text-blue_gray-800_02 text-left text-base font-semibold bg-gray-50">
              <p className="py-2">Address</p>
              <p className="pb-2">
               {address}
              </p>
            </div>
            <div className="border-gray-200_01 border border-solid   p-[5px] rounded-md flex flex-col pl-4 pr-[35px] sm:pt-5 sm:pr-5 text-blue_gray-800_02 text-left text-base font-semibold bg-gray-50">
              <p className="py-2">Role</p>
              <p className="pb-2"> {role}</p>
            </div>
            <div className="border-gray-200_01 border border-solid   p-[5px] rounded-md flex flex-col pl-4 pr-[35px] sm:pt-5 sm:pr-5 text-blue_gray-800_02 text-left text-base font-semibold bg-gray-50">
              <p className="py-2">Status</p>
              <p className="pb-2"> {statut}</p>
            </div>
            <div className="border-gray-200_01 border border-solid   p-[5px] rounded-md flex flex-col pl-4 pr-[35px] sm:pt-5 sm:pr-5 text-blue_gray-800_02 text-left text-base font-semibold bg-gray-50">
              <p className="py-2">Deplacement</p>
              <p className="pb-2"> {deplacement}</p>
            </div>
            <div className="border-gray-200_01 border border-solid   p-[5px] rounded-md flex flex-col pl-4 pr-[35px] sm:pt-5 sm:pr-5 text-blue_gray-800_02 text-left text-base font-semibold bg-gray-50">
              <p className="py-2">Entity Name</p>
              <p className="pb-2"> {entity_name}</p>
            </div>
            <Button
              color="blue_gray_800_02"
              size="4xl"
              variant="fill"
              shape="round"
              className="sm:px-5 font-bold"
              onClick={() => setEdit(true)}
            >
              Edit Information
            </Button>
          </div>
        </>
      ) : (
        <>
          <EditUserComponent data={data} setIsOpen={setIsOpen} />
        </>
      )}
    </CreateModal>
  );
}
