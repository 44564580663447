
import { Text, Img, Heading , SelectBox, } from "../../components";
import React, { useState, useEffect } from "react";
// import ReactPaginate from 'react-paginate';


const dropDownOptions = [
    { label: "Option1", value: "option1" },
    { label: "Option2", value: "option2" },
    { label: "Option3", value: "option3" },
  ];

  const InnerMenu:{id:number, name:string}[]=[
    {
        id:1,
        name:"All"
    },
    {
        id:2,
        name:"Transport"
    },
    {
        id:3,
        name:"Energy Use"
    },
    {
        id:4,
        name:"Production and Manufacturing"
    }
    ,
    {
        id:5,
        name:"Waste Management"
    }
    ,
    {
        id:6,
        name:"Supply"
    }
    ,
    {
        id:7,
        name:"Others"
    }
  ]
  
const Emission = () => {
  const [data, setData] = useState([{id:1, selected:false}, {id:2, selected:false},{id:3, selected:false},{id:4, selected:false}]);
  const [currentPage, setCurrentPage] = useState(0);

  const [org, setOrg] = useState(false);
  const [view, setView] = useState(false)


  const [selectAll, setSelectAll] = useState(false);
  const [rows, setRows] = useState<{id:number, selected:boolean}[]>(data);

  const handleRowCheckboxChange = (id: number) => {
    const updatedRows = rows.map(row => {
      if (row.id === id) {
        return { ...row, selected: !row.selected };
      }
      return row;
    });
    setRows(updatedRows);
    setSelectAll(updatedRows.every(row => row.selected));
  };

  const handleSelectAllChange = () => {
    const updatedRows = rows.map(row => ({ ...row, selected: !selectAll }));
    setRows(updatedRows);
    setSelectAll(!selectAll);
  };

  console.log(rows)

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch("https://api.example.com/data");
      const json = await response.json();
      setData(json);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage);
  };

  const itemsPerPage
   = 2;
  const offset = currentPage * itemsPerPage;
  const currentPageData = data.slice(offset, offset + itemsPerPage);

  return (
    < >

<div className="flex w-full  sm:w-full lg p-6 gap-8">
        
        <div className="flex flex-wrap gap-4">
        {
            InnerMenu.map((item:{id:number, name:string})=>{
                return(
                    <div key={item.id}  className={`font-bold md:border-[1.2px] flex justify-evenly items-center p-2 hover:bg-gray-100 ${item.id === 1 && "bg-gray-100"} rounded-md cursor-pointer`}>
                    <p>{item.name}</p>
                 </div>
                )
            })
        }
        </div>
      </div>
     <div className="flex justify-between md:flex-wrap lg:bg-red-500 gap-4 w-full ">
        <div className="sm:w-full md:w-[48%] w-1/4   lg:bg-red-400 lg:bg-red-100 h-[150px] rounded-lg border-[1.2px] border-gray-100 flex flex-col justify-evenly p-5">
          <h2 className="self-center text-lg">Number of Scope</h2>
          <h2 className="self-center text-[1.5em] font-bold ">03</h2>
        </div>

        <div className=" sm:w-full md:w-[48%] w-1/4  h-[150px] rounded-lg border-[1.2px] border-gray-100 flex flex-col justify-evenly p-5">
          <h2 className="self-center text-lg">Source of Emission</h2>
          <h2 className="self-center text-[1.5em] font-bold ">48</h2>
        </div>

        <div className="sm:w-full md:w-[48%] w-1/4  h-[150px] rounded-lg border-[1.2px] border-gray-100 flex flex-col justify-evenly p-5">
          <h2 className="self-center text-lg">Type of Operations</h2>
          <h2 className="self-center text-[1.5em] font-bold ">209</h2>
        </div>

        <div className="sm:w-full md:w-[48%] w-1/4  h-[150px] rounded-lg border-[1.2px] border-gray-100 flex flex-col justify-evenly p-5">
          <h2 className="self-center text-lg">Number of Calculations</h2>
          <h2 className="self-center text-[1.5em] font-bold ">454</h2>
        </div>
      </div>

<div className="flex md:flex-col gap-12">
                <div className="flex flex-col w-full">
                  <div
                    className="flex self-start pr-[9px] py-[9px] cursor-pointer"
                    onClick={() => setOrg(true)}
                  >
                    <Text
                      as="p"
                      className="self-end mt-[3px] !text-blue_gray-800_02"
                    >
                      Organization
                    </Text>
                  </div>
                  <SelectBox
                    shape="round"
                    indicator={
                      <Img src="images/img_arrowdown.svg" alt="arrow-down" />
                    }
                    name="selectoption"
                    placeholder={`Select Option`}
                    options={dropDownOptions}
                    className="gap-px sm:pr-5 font-medium border-blue_gray-100 border border-solid"
                  />
                </div>
                <div className="flex flex-col w-full">
                  <div className="flex self-start pr-[9px] py-[9px]">
                    <Text
                      as="p"
                      className="self-end mt-[3px] !text-blue_gray-800_02"
                    >
                      Organization
                    </Text>
                  </div>
                  <SelectBox
                    shape="round"
                    indicator={
                      <Img src="images/img_arrowdown.svg" alt="arrow-down" />
                    }
                    name="selectoption"
                    placeholder={`Select Option`}
                    options={dropDownOptions}
                    className="gap-px sm:pr-5 font-medium border-blue_gray-100 border border-solid"
                  />
                </div>
                <div className="flex flex-col w-full">
                  <div className="flex self-start pr-[9px] py-[9px]">
                    <Text
                      as="p"
                      className="self-end mt-[3px] !text-blue_gray-800_02"
                    >
                      Organization
                    </Text>
                  </div>
                  <SelectBox
                    shape="round"
                    indicator={
                      <Img src="images/img_arrowdown.svg" alt="arrow-down" />
                    }
                    name="selectoption"
                    placeholder={`Select Option`}
                    options={dropDownOptions}
                    className="gap-px sm:pr-5 font-medium border-blue_gray-100 border border-solid"
                  />
                </div>
              </div>
 
    
 
      <div className="overflow-x-auto w-full border-[1.4px] border-gray-100 rounded-sm">
        <table className="w-full bg-white">
          <thead>
            <tr>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500  tracking-wider">
                <input type="checkbox" className="rounded-md" checked={selectAll} onChange={()=>handleSelectAllChange()} />
              </th> 
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500  tracking-wider">
                Calculators
              </th>
              <th className="px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500  tracking-wider">
                {" "}
                Sources of emission
              </th>
              <th className="px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500  tracking-wider">
                {" "}
                Operations
              </th>
              <th className="px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500  tracking-wider">
                Years
              </th>
              <th className="px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500  tracking-wider">
                KG Co2c value
              </th>
        
              <th className="px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500  tracking-wider">
            Action
              </th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, pos) => (
              <tr key={pos}>
                <td className="pl-6 py-4 whitespace-no-wrap border-b border-gray-200">
                  <input type="checkbox" name="organ" className="rounded-md"  checked={row.selected}
                onChange={() => handleRowCheckboxChange(row.id)}/>
                </td>
                <td className="pr-2 py-4 whitespace-no-wrap border-b border-gray-200">
                  {" "}
                  <div className="flex justify-center items-center w-[72%] gap-2.5">
                    <div className="h-[30px] w-[50px]">
                      <Img
                        src="images/lady.png"
                        alt="image_one"
                        
                        // className="h-[30px] w-[50px] m-auto rounded-[50%] "
                      />
                    </div>
                    <div className="flex flex-col self-start items-start ">
                      <Heading size="xs" as="p" className="!text-gray-9text1">
                        Tanner Finsha
                      </Heading>
                      <p className="!text-blue_gray-600">
                        @Tannerfisher@gmail.com
                      </p>
                    </div>
                  </div>
                </td>
                <td className="px-2 py-4 whitespace-no-wrap border-b border-gray-200">
                  25C
                </td>
                <td className="px-2 py-2 whitespace-no-wrap border-b border-gray-200">
                25C
                </td>
          
                <td className="px-2 py-2 whitespace-no-wrap border-b border-gray-200">
                  2022
                </td>
                <td className="px-2 py-4 whitespace-no-wrap border-b border-gray-200">
                  20
                </td>
            
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 cursor-pointer" onClick={()=>setView(true)}>
                <div className="flex justify-between items-center">
                <Img src="images/trash.svg" alt="trash_icon" />
                <span>View</span>
               </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
     

        {/* <ReactPaginate
        previousLabel={'Previous'}
        nextLabel={'Next'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={Math.ceil(data.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={'pagination'}
        activeClassName={'active'}
      /> */}
      </div>
      <div className="sticky border-b border-gray-200 w-full flex justify-evenly h-16">
          {/* <div className="flex bg-red-400 w-[100%]"> */}
            <div className="w-auto flex justify-evenly items-center border-gray-100 gap-4">
              <Img src="images/previous.png" className="w-20" /> 
            </div>

            <div className="w-3/4 flex justify-center items-center border-gray-100 ">
              {[1,2,3,4,5,6].map((item:number, pos:number)=>{
                return(
                    <span key={item} className="border-[1.2px] p-2 rounded-md hover:bg-gray-100 m-2">{pos+1}</span>
                    )
              })}
            </div>

            <div className="w-auto flex justify-evenly items-center border-gray-100 gap-2">
              <Img src="images/next.png" className="w-20" /> 
            </div>
        </div>
    </>
  );
};

export default Emission;
