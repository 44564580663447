import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import CustomInput from "components/customizedInput";
import { Button, Img } from "components";
import { Link } from "react-router-dom";
import OtpScreen from "pages/otp";

import { ApiService } from "axiosRequest";

import AuthLayout from "authlayout";

const SignupForm = () => {
  const [success] = useState(false);


  let indicator:number[]=[]

  
  const checkPasswordStrength = (password) => {
    let strength = 0;
    const requirements = [
      /[a-z]+/, // Lowercase letters
      /[A-Z]+/, // Uppercase letters
      /[0-9]+/, // Numbers
      /[@$!%*?&]+/, // Symbols
    ];
  
    requirements.forEach((regex, pos) => {
      if (regex.test(password)) {
        strength += 1;
        if(!indicator.includes(pos)){
          indicator.push(pos)
        }
      }
    });
  
    return strength;
  };


  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      name: "",
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email("Invalid email address")
        .required("Email is required"),
      password: yup
        .string()
        .min(8, "Password must be at least 8 characters")
        .required("Password is required"),
      name: yup.string().required("Full Name is required"),
    }),
    onSubmit: async (values) => {
      try {
        const response = await new ApiService().registeration(values);
        console.log("response", response);
    
      } catch (err) {
      
      }
    },
  });

  return (
    <AuthLayout>
      {/* <AlertComponent
        isOpen={alert}
        setIsOpen={setAlert}
        message="Your account has been successfully created. Click below to continue to your dashboard magically."
        type="Account Created"
        path="/"
        btn="continue to dashboard"
      /> */}

      {/* <LoadingComponent isOpen={alert} setIsOpen={setAlert} /> */}
      {success ?<OtpScreen />:


      <form
        onSubmit={formik.handleSubmit}
        className="w-full h-full sm:p-6 md:p-3 p-5 flex flex-col justify-between gap-2"
      >
        <div className="w-full">
          <h1 className="sm:text-[1.5rem] text-[1.8rem] font-semibold mulish text-center">
            Welcome back
          </h1>

          <p className="sm:text-[12px] pb-4 text-center text-[14px]">
            Welcome back! Please enter your details
          </p>
        </div>

        <div className="w-full">
          <label htmlFor="name" className="">
            Full Name
          </label>
          <CustomInput
            name="name"
            type="text"
            placeholder="Enter your full name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            bgColor="bg-white"
            error={formik.touched.name && formik.errors.name}
            icon="images/user.png"
          />
        </div>

        <div className="w-full">
          <label htmlFor="email" className="block mb-2">
            Email
          </label>
          <CustomInput
            name="email"
            type="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            bgColor="bg-white"
            placeholder="Enter your email"
            error={formik.touched.email && formik.errors.email}
            icon="images/sms.png"
          />
        </div>

        <div className="w-full">
          <label htmlFor="password" className="block mb-2">
            Password
          </label>
          <CustomInput
            name="password"
            type="password"
            bgColor="bg-white"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            placeholder="Enter your password"
            error={formik.touched.password && formik.errors.password}
            icon="images/lock.png"
          />
          <div className="flex justify-between gap-x-2 pb-3 items-center w-full">
            <div
              className={`h-1 w-1/4 ${
                checkPasswordStrength(formik.values.password) > 0
                  ? "bg-yellow-100"
                  :"bg-[#e0e3e9]"
              } `}
            ></div>
            <div
              className={`h-1 w-1/4 ${
                checkPasswordStrength(formik.values.password) > 1
                  ? "bg-yellow-200"
                  : "bg-[#e0e3e9]"
              } `}
            ></div>
            <div
              className={`h-1 w-1/4 ${
                checkPasswordStrength(formik.values.password) > 2
                  ? "bg-yellow-300"                  
                  : "bg-[#e0e3e9]"
              } `}
            ></div>
            <div
              className={`h-1 w-1/4 ${
                checkPasswordStrength(formik.values.password) >= 3
                  ? "bg-green-400"
                  : "bg-[#e0e3e9]"
              } `}
            ></div>
          </div>
          {formik.values.password && !formik.errors.password && (
            <p className="w-full font-bold text-sm text-green-400 flex justify-end">
              Strong
            </p>
          )}
          <div className="text-[12px] text-[#e0e3e9] pb-4 ">
            <li
              className={`${
                formik.values.password.match(/[A-Z]+/) && formik.values.password.match(/[a-z]+/) ? "text-gray-700" : ""
              } `}
            >
              Upper and lowercase letters
            </li>
            <li
              className={`${
                formik.values.password.length >= 8 ? "text-gray-700" : ""
              } `}
            >
              More than 8 characters
            </li>
            <li
              className={`${
                formik.values.password.match(/[@$!%*?&]+/)
                  ? "text-gray-700"
                  : ""
              } `}
            >
              Contains a number or symbol
            </li>
          </div>
        </div>

        <Button
          type="submit"
          color="blue_gray_800_02"
          size="4xl"
          variant="fill"
          shape="round"
          className="w-full bg-blue_gray-800_02 text-white rounded-md p-2"
        >
          {formik.isSubmitting ? "Creating ..." : "Create Account"}
        </Button>

        <Button
          type="button"
          size="4xl"
          variant="fill"
          shape="round"
          className="w-full bg-blue_gray-800_02 text-white border-[1.2px] rounded-md  p-2 flex mr-2"
        >
          <Img src="images/Google.svg" />{" "}
          <span className="ml-2">Sign up with google</span>
        </Button>
        <p className="flex items-center justify-center mt-2">
          <span className="pr-2">Already have an account?</span>{" "}
          <Link to={"/"} className="font-bold text-gray-800">
            Sign In
          </Link>
        </p>

      </form>
}

    </AuthLayout>
  );
};

export default SignupForm;
