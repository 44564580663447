import React from 'react'
import Notification from "components/Notification";
 
function Action() {
  return (
    <div className='flex flex-col w-full h-full'>
        <Notification
        imageOne="images/active.png"
        description="Thanks John Smith, we had an issue with the prepaids schedule not tying out as expected -- can’t wait to move this out of Excel and into that new Numeric product! "
        time="20 mins ago"
        from="Michael Smith "
        to="Elixir Design System"
        actionMessage="commented on"
      />
       <Notification
        imageOne="images/guy.svg"
        description="Thanks John Smith, we had an issue with the prepaids schedule not tying out as expected -- can’t wait to move this out of Excel and into that new Numeric product! "
        time="20 mins ago"
        from="Michael Smith "
        to="Elixir Design System"
        actionMessage="commented on"
    
      />

<Notification
        imageOne="images/active.png"
        description="Thanks John Smith, we had an issue with the prepaids schedule not tying out as expected -- can’t wait to move this out of Excel and into that new Numeric product! "
        time="20 mins ago"
        from="Michael Smith "
        to="Elixir Design System"
        actionMessage="commented on"
      />
       <Notification
        imageOne="images/guy.svg"
        description="Thanks John Smith, we had an issue with the prepaids schedule not tying out as expected -- can’t wait to move this out of Excel and into that new Numeric product! "
        time="20 mins ago"
        from="Michael Smith "
        to="Elixir Design System"
        actionMessage="commented on"
      />
    </div>
  )
}

export default Action