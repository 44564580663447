import axios from "axios";
import { toast } from "react-toastify";
const base =
  process.env.NEXT_PUBLIC_BASE_URL || "https://qalqul-org.bomwave.com/api/v1";
const orgBaseUrl = "https://qalqul-org.bomwave.com/api/v1";
const usersBaseUrl = "https://qalqul-users.bomwave.com/api/v1";
const sensorsBaseUrl = "https://qalqul-sensor.bomwave.com/api/v1";
const entityBaseUrl = "https://qalqul-entity.bomwave.com/api/v1";
export class ApiService {
  //
  async registeration(data: Record<string, any>) {
    try {
      const res = await axios.post(`${usersBaseUrl}/signup`, data);
      return res.data;
    } catch (error) {
      toast.error(error?.response?.data?.message || "Unable to create user");
      return {error:true}
    }
  }
  

  async login(data: Record<string, any>) {
    try {
      const res = await axios.post(`${usersBaseUrl}/signin`, data);
      //TODO: set session
      console.log(res);
      return res.data;
    } catch (error) {
      toast.error(error?.response?.data?.message || "Unable login, please try again");
      return {error:true}
    }
  }

  async addOrganization(data: Record<string, any>) {
    
    try {
      const res = await axios.post(`${orgBaseUrl}/organizations`, data);
      console.log(res)
      return res.data;
    } catch (error) {
      console.log(error)
      toast.error(error?.response?.data?.message || "Unable to add ");
      return {error:true}
    }
  }

  async getOrganizations() {
    try {
      const res = await axios.get(`${base}/organizations`);
      console.log(res.data.data.rows.length, 'axios instances')
      return res.data.data;
    } catch (error) {
    }
    return {error:true}
  }

  async editOrganization(data: any) {
    try {
      const { organizationId, ...body } = data;
      
      const res = await axios.put(`${base}/organizations/${organizationId}`, body);
      console.log(res)
      return res.data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message || "Unable to edit organization")
      return {error:true}
    }
  }

  // ENTITY
  async addEntity(data: any) {
    try {
      const res = await axios.post(`${entityBaseUrl}/entity`, data);

      return res.data.data;
    } catch (error) {
      toast.error(error.message);
      return { error: true };
    }
  }
  async editEntity(data: any) {
    try {
      const{entityId} = data
      console.log(data)
      const res = await axios.put(`${entityBaseUrl}/entity/${entityId}`, data);

      return res.data.data;
    } catch (error) {
      toast.error(error.message);
      return { error: true };
    }
  }
  async getSingleEntity() {
    try {
      const res = await axios.get(`${entityBaseUrl}/entity`);
      return res.data.data;
    } catch (error) {
      toast.error(error.message);
      return { error: true };
    }
  }

  async getEntities() {
    try {
      const res = await axios.get(`${entityBaseUrl}/entity`);
      console.log(res, "in entity");
      return res.data.data;
    } catch (error) {
      toast.error(error.message);
      return { error: true };
    }
  }

  // USER
  async addUser(data: any) {
    try {
      console.log(data);
      const res = await axios.post(`${usersBaseUrl}/signup`, data);
      console.log(res);
      return res.data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message || "Unable to create user");
      return { error: true };
    }
  }

  async getAllUsers() {
    try {
      const res = await axios.get(`${usersBaseUrl}/users`);
      console.log(res, "in entity");
      return res.data.data;
    } catch (error) {
    }
  }

  async editUser(data: Record<string, any>) {
    try {
const {id, ...body} = data
      const res = await axios.put(`${usersBaseUrl}/users/${id}`, body);
      return res.data.data;
    } catch (error) {
      return {error:true}
    }
  }

  // SENSORS
  async addSensor(data: any) {
    try {
      console.log(data);
      const res = await axios.post(`${sensorsBaseUrl}/sensors`, data);
      console.log(res.data);
      return res.data;
    } catch (error) {
      toast.error(error?.response?.data?.message || "Unable to create user");
      return { error: true };
    }
  }

  async getSensors() {
    try {
      const res = await axios.get(`${sensorsBaseUrl}/sensors`);
      return res.data.data;
    } catch (error) {
    }
  }

  async editSensor(data: any) {
    try {
      const { id, ...body } = data;
      console.log(data, id, body)
      const res = await axios.put(`${sensorsBaseUrl}/sensors/${data.id}`, body);
      return res.data.data;
    } catch (error) {
    }
  }

  async deleteSensors(sensorId: number) {
    try {
      const res = await axios.delete(`${sensorsBaseUrl}/sensors/${sensorId}`);
      return res.data.data;
    } catch (error) {
    }
  }
}
