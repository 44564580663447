import { CloseSVG } from "assets/images";
import { ApiService } from "axiosRequest";
import { Button } from "components/Button";
import { Heading } from "components/Heading";
import { Img } from "components/Img";
import { Input } from "components/Input";
import CreateSensorForm from "components/addSensor";
import Paginator from "components/pagination";
import ViewSensor from "modals/viewSensor";
import React, { useState, useEffect } from "react";



const Sensors = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchBarValue, setSearchBarValue] = React.useState("");
  const [viewSensor, setViewSensor] = useState(false)
  const [addSensor, setAddSensor] = useState(false)
  const [currentData, setCurrentData] = useState<any>({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    fetchData();
  }, [addSensor, viewSensor]);


  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const setCurentView=(currentItem)=>{
    setCurrentData(currentItem)
    setViewSensor(true)
  }

  const fetchData = async () => {
    setLoading(true)
    try {
      const response = await new ApiService().getSensors();
      setData(response.rows);
      setLoading(false)
    } catch (error) {
 
    }
  };
  const itemsPerPage = 10;
  const offset = (currentPage - 1) * itemsPerPage;
  const currentPageData = data?.length && data.reverse().slice(offset, offset + itemsPerPage);

  return (
    < >

    <ViewSensor isOpen={viewSensor} setIsOpen={setViewSensor } data={currentData}/>
    <CreateSensorForm isOpen={addSensor} setIsOpen={setAddSensor } />
      <div className="flex md:flex-wrap w-full gap-4">
        <div className="w-full sm:w-full md:w-[48%] lg:w-1/4 h-[146px] rounded-lg border-[1.2px] border-gray-100 flex flex-col justify-evenly px-4">
          <h2 className="self-center text-lg">Number of organization</h2>
          <h2 className="self-center text-lg">25</h2>
        </div>
        <div className="w-full sm:w-full md:w-[48%] lg:w-1/4 h-[146px] sm:h-auto rounded-lg border-[1.2px] border-gray-100 flex flex-col justify-evenly px-4">
          <h2 className="self-center text-lg">Total emission in KG of CO2</h2>
          <div className="w-full flex justify-center item-center text-lg">
            <Img src="images/pie1.png" alt="pie" className="w-[35%]" />
          </div>
        </div>
        <div className="w-full sm:w-full md:w-[48%] lg:w-1/4 h-[146px] sm:h-auto rounded-lg border-[1.2px] border-gray-100 flex flex-col justify-evenly px-4">
          <h2 className="self-center text-lg">Percentage Reduction</h2>
          <div className="w-full flex justify-center item-center text-lg">
            <Img src="images/pie2.png" alt="pie" className="w-[35%]" />
          </div>
        </div>
        <div className="w-full sm:w-full md:w-[48%] lg:w-1/4 h-[146px] sm:h-auto rounded-lg border-[1.2px] border-gray-100 flex flex-col justify-evenly px-4">
          <h2 className="self-center text-lg">Economy</h2>
          <div className="w-full flex justify-center item-center text-lg">
            <Img src="images/pie3.png" alt="pie" className="w-[35%]" />
          </div>
        </div>
      </div>

      {/* Search and filter section */}
      <div className="flex w-full sm:flex-wrap gap-5 ">
        <div className="sm:w-full w-6/12">
          {" "}
          <Input
            color="white_A700"
            size="lg"
            variant="fill"
            shape="round"
            name="search"
            placeholder={`Search`}
    
            value={searchBarValue}
            onChange={(e: string) => setSearchBarValue(e)}
            prefix={
              <Img
                src="images/img_search.svg"
                alt="search"
                className="cursor-pointer"
              />
            }
            suffix={
              searchBarValue?.length > 0 ? (
                <CloseSVG
                  onClick={() => setSearchBarValue("")}
                  fillColor="#667084ff"
                />
              ) : null
            }
            className="h-12 p-4"
          />
        </div>
        <div className="sm:w-full w-6/12 flex gap-2">
          <div className="w-2/4 flex justify-evenly items-center rounded-md ">
            <Button
              color="blue_gray_800_02"
              size="lg"
              variant="fill"
              shape="round"
              className=" font-bold p-6"
              onClick={() => setAddSensor(true)}
            >
              Add Sensor
            </Button>
          </div>
          <div className="w-2/4 flex justify-evenly items-center rounded-md border-[1.5px] border-gray-100 ">
            <Img src="images/exportIcon.png" className="w-6" /> <p>Export</p>
          </div>
          <div className="w-2/4 flex justify-evenly items-center rounded-md border-[1.5px] border-gray-100 ">
            <Img src="images/filter.png" className="w-6" /> <p>Filter By</p>
          </div>
        </div>
      </div>
      <div className="overflow-x-auto w-full border-[1.4px] border-gray-100 rounded-sm">
        <table className="w-full bg-white">
          <thead>
            <tr>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                <input type="checkbox" className="rounded-md" />
              </th>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Organization
              </th>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                {" "}
                Contact
              </th>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Percentage(%)
              </th>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Status
              </th>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Statistics
              </th>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                action
              </th>
            </tr>
          </thead>
          <tbody>
            {data && data?.length ? currentPageData.map((item, pos) => (
              <tr key={pos}>
                <td className="pl-6 py-4 whitespace-no-wrap border-b border-gray-200">
                  <input type="checkbox" name="organ" className="rounded-md" />
                </td>
                <td className="pr-6 py-4 whitespace-no-wrap border-b border-gray-200">
                  {" "}
                  <div className="flex justify-center items-center w-[72%] gap-2.5">
                    <div className="h-[30px] w-[50px]">
                      <Img
                        src="images/lady.png"
                        alt="image_one"
                        // className="h-[30px] w-[50px] m-auto rounded-[50%] "
                      />
                    </div>
                    <div className="flex flex-col self-start items-start ">
                      <Heading size="xs" as="p" className="!text-gray-9text1">
                       {item.model}
                      </Heading>
                      <p className="!text-blue_gray-600">
                        @Tannerfisher@gmail.com
                      </p>
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                  20
                </td>
                <td className="px-2 py-2 whitespace-no-wrap border-b border-gray-200">
                  20%
                </td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                  {!item.status ? (
                    <Img src="images/inactive.png" className="w-14" />
                  ) : (
                    <Img src="images/active.png" className="w-14" />
                  )}
                </td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                  Items
                </td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 cursor-pointer" onClick={()=>setCurentView(item)}>
                  View
                </td>
              </tr>
            )): loading ? <div className="p-3 text-[16px] text-green-600">Fetching Sensor's Data... </div>:<div className="p-3 text-[16px] text-yellow-500">Data not found</div>}
          </tbody>
        </table>
       {data && data?.length &&  <Paginator
        totalPages={Math.ceil(data.length / itemsPerPage)}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />}
      </div>
    
    </>
  );
};

export default Sensors;
