import { Img } from 'components/Img';
import React, { ReactNode, useState } from 'react';

interface Props {
  placeholder?: string;
  label?: string;
  name: string;
  type: string;
  value: any;
  onChange: React.FormEventHandler;
  onBlur: React.FormEventHandler;
  error: string;
  icon?:string;
  bgColor?: string; // Optional background color
}

const CustomInput = ({
  label,
  name,
  type,
  value,
  placeholder,
  onChange,
  onBlur,
  error,
  icon,
  bgColor,
  ...props
}: Props) => {
  const [pass, setTogglePass] = useState(false)

  const handleTogglerPassword =()=>{
setTogglePass(!pass)
  }
  
  return (
  <>
    <div
      className={`mt-2 rounded-md border border-gray-200 focus:border-red-500 p-3 ${ bgColor ? bgColor: ''} border-solid rounded-lg ${
        error
          ? 'border-red-500 text-red-500 placeholder-red-500 focus:ring-red-500 focus:border-red-500'
          : 'text-gray-900 placeholder-gray-400'
      } `}
    >
     {
      label && <label htmlFor={name} className="block text-sm font-medium text-gray-700">
       {label.charAt(0).toUpperCase() + label.slice(1)}
     </label>
     }
      <div className="flex justify-start items-center">
      {icon && <Img src={icon} alt={'icon'} className="h-5 w-5 m-2" loading='lazy' />}
       
        <input
          type={type === 'password' && pass ? 'text' : type}
          id={name}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          className={`block w-full px-10 h-8 bg-red-400  rounded-md sm:text-sm ${
            error
              ? 'border-red-500 text-red-500 placeholder-red-500 focus:ring-red-500 focus:border-red-500'
              : 'text-gray-900 placeholder-gray-400 focus:border-gray-500'
          }`}
          {...props}
        />
         {type === 'password' && <p className="cursor-pointer px-2" onClick={handleTogglerPassword} >{ pass? "Hide":'Show'}</p>}
      </div>
    </div>
  </>
  );
};

export default CustomInput;
