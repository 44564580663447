import React from "react";

const shapes = {
  circle: "rounded-[50%]",
  square: "rounded-[0px]",
  round: "rounded-[12px]",
} as const;
const variants = {
  fill: {
    deep_purple_A400: "bg-deep_purple-A400 text-white-A700",
    gray_100_01: "bg-gray-100_01 text-deep_purple-A400",
    teal_50: "bg-teal-50",
    gray_50_01: "bg-gray-50_01 text-blue_gray-800_02",
    gray_100: "bg-gray-100 text-blue_gray-800_02",
    white_A700: "bg-white-A700 text-gray-900",
    light_green_50: "bg-light_green-50 text-teal-800",
    red_50_01: "bg-red-50_01 text-red-400",
    blue_gray_800_02: "bg-blue_gray-800_02 text-white-A700",
  },
  outline: {
    blue_gray_50: "border-blue_gray-50 border-b border-solid text-blue_gray-800_02",
  },
} as const;
const sizes = {
  xl: "h-[44px] px-6 text-sm",
  "3xl": "h-[51px] px-[13px] text-base",
  md: "h-[36px] px-3.5 text-sm",
  "4xl": "h-[56px] px-[35px] text-base",
  lg: "h-[40px] px-[15px] text-sm",
  sm: "h-[30px] px-2.5",
  xs: "h-[24px] pl-5 pr-2 text-xs",
  "2xl": "h-[48px] px-3",
  "5xl": "h-[72px] pl-[23px] pr-4 text-sm",
} as const;

type ButtonProps = Omit<
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  "onClick"
> &
  Partial<{
    className: string;
    leftIcon: React.ReactNode;
    rightIcon: React.ReactNode;
    onClick: () => void;
    shape: keyof typeof shapes;
    variant: keyof typeof variants;
    size: keyof typeof sizes;
    color: string;
  }>;
const Button: React.FC<React.PropsWithChildren<ButtonProps>> = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape,
  variant = "outline",
  size = "5xl",
  color = "white_A700",
  ...restProps
}) => {
  return (
    <button
      className={`${className} flex items-center justify-center text-center cursor-pointer ${(shape && shapes[shape]) || ""} ${(size && sizes[size]) || ""} ${(variant && variants[variant]?.[color as keyof (typeof variants)[typeof variant]]) || ""}`}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};

export { Button };
