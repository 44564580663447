// components/Paginator.tsx

import { Button } from "components/Button";
import { Img } from "components/Img";
import React from "react";

interface PaginatorProps {
  totalPages: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}

const Paginator: React.FC<PaginatorProps> = ({ totalPages, currentPage, onPageChange }) => {

  const handlePageChange = (page: number) => {
    onPageChange(page);
  };
  

  return (
    <div className="sticky border-b border-gray-200 w-full flex justify-evenly h-16">
  <div className="w-auto flex justify-evenly items-center border-gray-100 gap-4">
  <Button
        disabled={currentPage === 1}
        onClick={() => handlePageChange(currentPage - 1)}
      >
        <Img src="images/previous.png" className="w-24"/>
      </Button>
  </div>
    
    <div className="w-3/4 flex justify-center items-center border-gray-100 ">
    {[...Array(totalPages).keys()].map((page) => (
        <span
          key={page}
          className={`border-[1.2px] p-2 rounded-md hover:bg-blue_gray-800_02 hover:text-white-A700 m-2 ${currentPage === page + 1 ? 'bg-blue_gray-800_02 text-white-A700' : ''}`}
          onClick={() => handlePageChange(page + 1)}
        >
          {page + 1}
        </span>
      ))}
    </div>
  <div className="w-auto flex justify-evenly items-center border-gray-100 gap-2">
  <Button
        disabled={currentPage === totalPages}
        onClick={() => handlePageChange(currentPage + 1)}
      >
       <Img src="images/next.png" className="w-20"/>
      </Button>
  </div>
     
    </div>
  );
};

export default Paginator;
