import { Img } from "components/Img";
import React from "react";

function MobileHeader() {
  return (
    <div className="py-6 mb-3 border-b-[1.2px] h-20 sm:flex w-[100%] justify-end items-center md:hidden hidden ">
      <span className="px-4">
        <Img src="images/menu.svg" alt="menu" />
      </span>
    </div>
  );
}

export default MobileHeader;
