import React, { useRef, useEffect } from "react";

interface OtpInputProps {
  length: number;
  otp: string[];
  setOtp: React.Dispatch<React.SetStateAction<string[]>>;
}

const OtpInput: React.FC<OtpInputProps> = ({ length, otp, setOtp }) => {
  const inputRefs = useRef<HTMLInputElement[]>(Array(length).fill(null));
  console.log(otp);
  const handleInputChange = (value: string, index: number) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Move to the next input
    if (index < length - 1 && value !== "") {
      inputRefs.current[index + 1].focus();
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const clipboardText = event.clipboardData.getData("text");

    // Extract only numbers from clipboard text
    const numbersOnly = clipboardText.replace(/\D/g, "");

    // Update the OTP state
    setOtp(numbersOnly.slice(0, length).split(""));
  };

  // Handle Ctrl+V paste
  useEffect(() => {
    const handleCtrlVPaste = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.code === "KeyV") {
        navigator.clipboard.readText().then((clipboardText) => {
          const numbersOnly = clipboardText.replace(/\D/g, "");
          setOtp(numbersOnly.slice(0, length).split(""));
        });
      }
    };

    window.addEventListener("keydown", handleCtrlVPaste);

    return () => {
      window.removeEventListener("keydown", handleCtrlVPaste);
    };
  }, [length]);

  return (
    <div className="h-150 w-full flex gap-6 items-center justify-center">
      {otp.map((digit, index) => (
        <input
          key={index}
          ref={(ref) => (inputRefs.current[index] = ref!)}
          type="text"
          value={digit}
          maxLength={1}
          onChange={(e) => handleInputChange(e.target.value, index)}
          onPaste={handlePaste}
          className="focus:border-blue-100 border-[1.2px]"
          style={{
            border: "1px solid gray",
            borderRadius: "4px",
            height: "60px",
            width: "60px",
            padding: "10px",
            marginTop: "10px",
            fontSize: "1.2em",
            fontWeight: "bolder",
            textAlign: "center",
          }}
        />
      ))}
    </div>
  );
};
export default OtpInput;
