import React from "react";
import { CloseSVG } from "../../assets/images";
import { Img, Text, Heading, Input } from "..";
import { Menu, Sidebar } from "react-pro-sidebar";
import {  menuList,IMenusEnum } from "data/data";

interface Props {
  className?: string;
  toggleMenu:React.Dispatch<React.SetStateAction<string>>,
  menus:string,
  setDisplayMenu:React.Dispatch<React.SetStateAction<boolean>>
}



export default function SidebarComponent({ setDisplayMenu, toggleMenu, menus, ...props }: Props) {
  const [searchBarValue, setSearchBarValue] = React.useState("");
  const [collapsed, setCollapsed] = React.useState(false);

  // use this function to collapse/expand the sidebar
   const collapseSidebar = () =>{
    setCollapsed(!collapsed);

  }


  const handleToggleMenu = (value) =>{
    setDisplayMenu(false)
    toggleMenu(value)
  }
 

  return (
    <Sidebar
      {...props}
      width="312px !important"
      collapsedWidth="80px !important"
      collapsed={collapsed}
    >
      {/* <div className="self-stretch m border-b-[1.2px] border-white-A700 " /> */}

      <Menu
        menuItemStyles={{
          button: {
            padding: " ",
            borderRadius: "16px",
            margin: " ",
            [`&:hover, &.ps-active`]: {
              color: "#344054",
              fontWeight: "700 !important",
              backgroundColor: "#f3f4f6 !important",
            },
          },
        }}
        className="flex h-screen flex-col items-start w-full border-r-[1.2px] border-gray-100"
      >
        <div className="flex self-start self-center p-2.5 w-[85%]">
          <Input
            color="white_A700"
            size="sm"
            variant="fill"
            shape="round"
            name="search"
            placeholder={`Search`}
            value={searchBarValue}
            onChange={(e: string) => setSearchBarValue(e)}
            prefix={
              <Img
                src="images/img_search.svg"
                alt="search"
                className="cursor-pointer"
              />
            }
            suffix={
              searchBarValue?.length > 0 ? (
                <CloseSVG
                  onClick={() => setSearchBarValue("")}
                  fillColor="#667084ff"
                />
              ) : null
            }
            className="w-[85%] mt-4 gap-2 sm:pr-5 text-blue_gray-500_01 font-inter border-solid border-blue_gray-100_02 p-4 my-4"
          />
        </div>

        <div className="flex flex-col self-center items-center  gap-[0.24px] w-[85%]">
          <div className={`flex self-start items-center gap-4 p-2.5 ${ menus=== IMenusEnum.Dashboard ? "bg-gray-100":"" } w-[85%] rounded-md`} onClick={()=>handleToggleMenu(IMenusEnum.Dashboard)}>
            <Img
              src="images/img_element_3.svg"
              alt="elementthree"
              className="h-[24px] w-[24px] ml-[5px]"
            />
            <Text as="button" className={`!text-gray-900 `}>
              Dashboard
            </Text>
          </div>
          <div className="flex self-start items-center gap-4 p-2.5 ml-2">
            <Text size="s" as="p" className="w-full">
              Organization
            </Text>
          </div>

        
         {menuList.map((item:{src:string,to:string,title:string},pos:number)=>{
          return(
            <div className={`flex self-start items-center gap-4 p-2.5 my-2 w-[85%] rounded-md ${ menus=== item.to && "bg-gray-100" }`} key={pos} onClick={()=>handleToggleMenu(item.to)} >
            <Img
              src={item.src}
              alt={item.title}
              className="h-[24px] w-[24px] ml-[5px] "
            />
            <Text as="button" className="!text-gray-900">
              {item.title}
            </Text>
          </div>
          )
         })}
     
        </div>

        <div className="flex flex-col self-stretch mt-20 py-2">
          <div className="h-px bg-blue_gray-50" />
          <div className="pt-8 flex justify-between items-center">
            <div className="flex justify-center items-center w-[72%] gap-2.5">
              <div className="h-[42px] w-[42px] relative">
                <Img
                  src="images/img_image.png"
                  alt="image_one"
                  className="h-[40px] w-[40px] top-0 right-0 left-0 m-auto rounded-[50%] absolute"
                />
                <Img
                  src="images/img_verified_tick.svg"
                  alt="verifiedtick"
                  className="h-[14px] w-[14px] bottom-0 right-0 m-auto absolute"
                />
              </div>
              <div className="flex flex-col self-start items-start ">
                <Heading size="xs" as="p" className="!text-gray-900_01">
                  Alison Eyo
                </Heading>
                <Text as="p" className="!text-blue_gray-600">
                  @thealisoneyo
                </Text>
              </div>
            </div>
            <Img
              src="images/img_icon_sign_out.svg"
              alt="iconsignout_one"
              className="h-[20px] w-[20px] mr-3.5"
            />
          </div>
        </div>
      </Menu>
      
    </Sidebar>
  );
}
