import React, { SetStateAction, useState } from "react";
import { Button } from "../../components";
import CreateModal from "modals/customModal";
import useStore from "zustandState";
import EditSensor from "components/editSensor.tsx";

interface Props {
  className?: string;
  isOpen:boolean,
  setIsOpen:React.Dispatch<SetStateAction<boolean>>
  data?:IData
}

interface IData {
  end_date: string;
  model:string,
  record:string
  libelle:string,
  id: number;
  start_date: string;
  entity_id:string;
  status: boolean;
}

export default function ViewSensor({ isOpen, setIsOpen, data, ...props }: Props) {
  const [edit, setEdit] = useState(false);
const entities= useStore(state=>state.entities)
if(!data) return 
const { model, end_date, start_date, record, libelle, id} =data



const entity = entities.find(item=> item.id === id)
console.log(entities, id)
const formatedData = {...data, entity_name:entity?.entityName}
  return (
    <CreateModal isOpen={isOpen} setIsOpen={setIsOpen} title={entity?.entityName} img="images/lady.png" >
     { !edit ? <>
   
    <div className="flex flex-col w-full gap-6 mb-3">
      <div className=" border-gray-200_01 border border-solid  p-[5px] rounded-md flex flex-col pl-4 pr-[35px] sm:pt-5 sm:pr-5 text-blue_gray-800_02 text-left text-base font-semibold bg-gray-50">
       <p className="py-2">Entity Name</p>
       <p  className="pb-2"> {entity?.entityName}</p>
      </div>
        <div className=" border-gray-200_01 border border-solid  p-[5px] rounded-md flex flex-col pl-4 pr-[35px] sm:pt-5 sm:pr-5 text-blue_gray-800_02 text-left text-base font-semibold bg-gray-50">
       <p className="py-2">Model</p>
       <p className="pb-2" > {model}</p>
      </div>
        <div className=" border-gray-200_01 border border-solid  p-[5px] rounded-md flex flex-col pl-4 pr-[35px] sm:pt-5 sm:pr-5 text-blue_gray-800_02 text-left text-base font-semibold bg-gray-50">
       <p className="py-2">Record</p>
       <p  className="pb-2"> {record}</p>
      </div>
      <div className="border-gray-200_01 border border-solid   p-[5px] rounded-md flex flex-col pl-4 pr-[35px] sm:pt-5 sm:pr-5 text-blue_gray-800_02 text-left text-base font-semibold bg-gray-50">
       <p className="py-2">Label</p>
       <p  className="pb-2"> {libelle}</p>
      </div>
      <div className="border-gray-200_01 border border-solid   p-[5px] rounded-md flex flex-col pl-4 pr-[35px] sm:pt-5 sm:pr-5 text-blue_gray-800_02 text-left text-base font-semibold bg-gray-50">
       <p className="py-2">Start Date</p>
       <p  className="pb-2"> {start_date}</p>
      </div>
      <div className="border-gray-200_01 border border-solid   p-[5px] rounded-md flex flex-col pl-4 pr-[35px] sm:pt-5 sm:pr-5 text-blue_gray-800_02 text-left text-base font-semibold bg-gray-50">
       <p className="py-2">End Date</p>
       <p  className="pb-2"> {end_date}</p>
      </div>
      <Button
        color="blue_gray_800_02"
        size="4xl"
        variant="fill"
        shape="round"
        className="sm:px-5 font-bold"
        onClick={()=>setEdit(true)}
      >
        Edit Information
      </Button>
    </div>
     </>:<>
     <EditSensor data={formatedData} setIsOpen={setIsOpen}/>
     
     </>}

    

    </CreateModal>
  );
}












