import React, { useEffect, useState } from "react";
import { Text, Img, Heading, SelectBox } from "../../components";

import DashboardLayout from "layout/dashboard_layout";
import { IMenusEnum } from "data/data";
import Entities from "pages/entities";
import Users from "pages/users";
import Sensors from "pages/sensors";
import Emission from "pages/emission";
import Operation from "pages/operation";
import BarChart from "components/charts/customChart";
import { chartData } from "dummyData";
import { ApiService } from "axiosRequest";
import Action from "pages/action";
import Organization from "components/organization";
import useStore from "zustandState";


// import  SelectedOrganization from "components/organization";
const dropDownOptions = [
  { label: "Organization", value: "organization" },

];

export default function DASHBOARDT() {
  const [menus, toggleMenu] = useState("dashboard");
  const [org, setOrg] = useState(false);
  const setEntities = useStore((state)=>state.setEntities)
  const setOrganizations = useStore((state) => state.setOrganizations);
  const [isEntityModal, setIsEntityModal] = useState(false);

  const handleSwitchOrganization = (e)=>{
    setOrg(true)
  }


  

  useEffect(() => {
    fetchData();
  }, [menus, isEntityModal]);


  const fetchData = async () => {
    try {
      const response = await new ApiService().getOrganizations();
      const setEntity = await new ApiService().getEntities()
      setOrganizations(response.rows)
      setEntities(setEntity)
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  return (
    <>
      <DashboardLayout menus={menus} toggleMenu={toggleMenu}>
        <div className="flex flex-col w-[100%] sm:w-full p-6 gap-8">
          {menus === "dashboard" ? (
            <>
             {menus ==='dashboard' && !org ? <> <div className="flex md:flex-col gap-12">
                <div className="flex flex-col w-full">
                  <div
                    className="flex self-start pr-[9px] py-[9px] cursor-pointer"
                    onClick={() => setOrg(true)}
                  >
                    <Text
                      as="p"
                      className="self-end mt-[3px] !text-blue_gray-800_02"
                    >
                      Organization
                    </Text>
                  </div>
                  <SelectBox
                    shape="round"
                    indicator={
                      <Img src="images/img_arrowdown.svg" alt="arrow-down" />
                    }
                    name="selectoption"
                    placeholder={`Select Option`}
                    options={dropDownOptions}
                    onChange={(e)=>handleSwitchOrganization(e)}
                    className="gap-px sm:pr-5 font-medium border-blue_gray-100 border border-solid"
                  />
                </div>
                <div className="flex flex-col w-full">
                  <div className="flex self-start pr-[9px] py-[9px]">
                    <Text
                      as="p"
                      className="self-end mt-[3px] !text-blue_gray-800_02"
                    >
                      Organization
                    </Text>
                  </div>
                  <SelectBox
                    shape="round"
                    indicator={
                      <Img src="images/img_arrowdown.svg" alt="arrow-down" />
                    }
                    name="selectoption"
                    placeholder={`Select Option`}
                    options={dropDownOptions}
                    onChange={(e)=>handleSwitchOrganization(e)}
           
                    className="gap-px sm:pr-5 font-medium border-blue_gray-100 border border-solid"
                  />
                </div>
                <div className="flex flex-col w-full">
                  <div className="flex self-start pr-[9px] py-[9px]">
                    <Text
                      as="p"
                      className="self-end mt-[3px] !text-blue_gray-800_02"
                    >
                      Organization
                    </Text>
                  </div>
                  <SelectBox
                    shape="round"
                    indicator={
                      <Img src="images/img_arrowdown.svg" alt="arrow-down" />
                    }
                    name="selectoption"
                    placeholder={`Select Option`}
                    options={dropDownOptions}
                    onChange={(e)=>handleSwitchOrganization(e)}
                    className="gap-px sm:pr-5 font-medium border-blue_gray-100 border border-solid"
                  />
                </div>
              </div>
                    {/* first */}
              <div className="flex md:flex-col justify-center gap-6">

                <div className="flex flex-col w-full border-gray-300 border border-solid bg-white-A700 rounded-[12px]">
                  <Heading
                    size="lg"
                    as="h1"
                    className="mt-1 ml-[11px] md:ml-0 !text-gray-900 !text-[21.75px]"
                  >
                    Total Emission
                  </Heading>

                  <BarChart data={chartData.data} labels={chartData.labels} type="line"/>
                </div>

                <div className="flex flex-col w-full gap-[18px] p-3 border-gray-300 border border-solid bg-white-A700 rounded-[12px]">
                  <div className="flex justify-center items-center gap-1.5">
                    <div className="flex">
                      <Heading
                        as="h2"
                        className="self-end mt-0.5 !text-gray-900 !text-[16.11px] !font-bold"
                      >
                        Objectives & Progress
                      </Heading>
                    </div>
                    <div className="flex justify-center items-start w-[13%] gap-2">
                      <div className="h-[4px] w-[38%] mt-[5px] bg-teal-50_01 rounded-sm" />
                      <Text size="s" as="p" className="!text-gray-900">
                        Goals
                      </Text>
                    </div>
                    <div className="flex justify-center items-start w-[16%] gap-2">
                      <div className="h-[4px] w-[29%] mt-[3px] bg-blue_gray-800_02 rounded-sm" />
                      <Text size="s" as="p" className="!text-gray-900">
                        Progress
                      </Text>
                    </div>
                  </div>
                  <div className="flex sm:flex-col justify-between gap-5">
                  <BarChart data={chartData.data2} labels={chartData.labels2} type="bar2"/>
                  </div>
                </div>

              </div>


              {/* second */}
              <div className="flex md:flex-col justify-center items-center gap-6">
                <div className="flex flex-col w-full gap-[18px] p-3 border-gray-300 border border-solid bg-white-A700 rounded-[12px]">
                  <div className="flex justify-between gap-5">
                    <div className="flex pr-[9px] py-[9px]">
                      <Heading
                        as="h3"
                        className="self-start !text-gray-900 !text-[16.11px] !font-bold"
                      >
                        Trends
                      </Heading>
                    </div>
                    <SelectBox
                      size="xs"
                      shape="round"
                      indicator={
                        <Img
                          src="images/img_24_chevrondown.svg"
                          alt="24/chevron-down"
                        />
                      }
                      name="zipcode"
                      placeholder={`2024`}
                      options={dropDownOptions}
                      className="w-[20%] gap-px sm:pr-5 !text-gray-900 font-bold border-blue_gray-100_01 border border-solid"
                    />
                  </div>

                  <div className="flex sm:flex-col justify-between gap-5">
                  <BarChart data={chartData.data2} labels={chartData.labels2} type="bar"/>
                  
                  </div>
                </div>
                <div className="flex flex-col w-full gap-3 p-3 border-gray-300 border border-solid bg-white-A700 rounded-[12px]">
                  <div className="flex self-start pt-1 pr-1">
                    <Heading
                      size="lg"
                      as="h4"
                      className="!text-gray-900 !text-[21.75px]"
                    >
                      Daily broadcast in real time
                    </Heading>
                  </div>
                  <div>
                    <div className="flex flex-col items-end gap-2">
                    <BarChart data={chartData.data} labels={chartData.labels} type="line2"/>
                    </div>
                  </div>
                </div>
              </div></>:<>
              <Organization isOpen={isEntityModal} setIsOpen={setIsEntityModal}/>
              
              </>}
            </>
          ) : menus === IMenusEnum.Users ? (
            <Users menu={menus}/>
          ) : menus === IMenusEnum.Sensor ? (
            <Sensors/>
          ) : menus === IMenusEnum.Emission ? (
            <Emission/>
          ) : menus === IMenusEnum.Operation ? (
            <Operation menu={menus}/>
          ) : menus === IMenusEnum.Action ? (
            <Action/>
          ) : menus === IMenusEnum.Entities ? (
            <Entities menu={menus} toggleMenu={toggleMenu}/>
          ) : (
            <div>settings</div>
          )}
        </div>
      </DashboardLayout>
    </>
  );
}
