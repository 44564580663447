import React from "react";
import { Img, Text } from "..";

interface Props {
  className?: string;
  imageOne: string;
  time: string;
  description: string;
  to: string;
  from: string;
  actionMessage: string;
}

export default function DASHBOARDTenNotification3({
  imageOne,
  to,
  from,
  time,
  actionMessage,
  description,
  ...props
}: Props) {
  return (
    <div {...props}>
      <div className="flex md:flex-col self-stretch justify-center items-start pt-5 gap-6 px-10">
        <Img
          src={imageOne}
          alt="image_one"
          className="h-[48px] w-[48px] rounded-[50%]"
        />
        <div className="flex flex-col gap-4 flex-1">
          <div className="flex sm:flex-col justify-between gap-5">
            <div className="flex sm:w-full">
              <Text as="p" className="!text-blue_gray-800 !font-normal">
                <span className="text-blue_gray-800 font-bold">{from}</span>
                <span className="text-blue_gray-800">{actionMessage} </span>
                <span className="text-blue_gray-800 font-bold">{to}</span>
              </Text>
            </div>
            <div className="flex sm:justify-start justify-center items-start sm:w-full gap-2">
              <Text as="p">{time}</Text>
              <div className="h-[6px] w-[6px] mt-1 bg-pink-300 rounded-[50%]" />
            </div>
          </div>
          <Text as="p" className="!text-blue_gray-800 !font-normal !leading-5">
            {description}
          </Text>
        </div>
      </div>
      <p className="w-full border-b-[1.2px]  pt-3 h-px md:h-auto"></p>
    </div>
  );
}
