import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import CustomInput from "components/customizedInput";
import { Button, Heading, Img } from "components";
import { Link } from "react-router-dom";
import OtpScreen from "pages/otp";
import AuthLayout from "authlayout";

const ForgotPassword = () => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email("Invalid email address")
        .required("Email is required"),
    }),
    onSubmit: async (values) => {
      try {
        const response = await axios.post("/api/signin", values);
        setSuccess(true);
        setError("");
      } catch (err) {
        setSuccess(false);
        setError(err.response?.data.message || "An error occurred");
      }
    },
  });

  return (
    <AuthLayout>
      <form onSubmit={formik.handleSubmit} className="w-full h-full sm:p-8 md:p-3 p-5 flex flex-col justify-start">
        <div className="flex items-center w-full justify-center py-4">
          <Img src="images/lock2.svg" />
        </div>
        <h1 className="sm:text-[1.5rem] text-[2rem] font-bold mulish text-center">
          Welcome back
        </h1>
        <p className="sm:text-[12px] pb-4 text-center text-[14px] mb-3">
          No worries, we will send you a rest instructions
        </p>
        <div className="mb-4">
          <label htmlFor="email" className="block mb-2">
            Email
          </label>
          <CustomInput
            name="email"
            type="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            bgColor="bg-white"
            placeholder="Enter your email"
            error={formik.touched.email && formik.errors.email}
            icon="images/sms.png"
          />
        </div>

        <Button
          type="submit"
          color="blue_gray_800_02"
          size="4xl"
          variant="fill"
          shape="round"
          className="w-full bg-blue_gray-800_02 text-white rounded-md p-2"
        >
          {formik.isSubmitting ? "Submitting ..." : "Reset Password"}
        </Button>
        <Link
          to="/"
          className="w-full border-[1.2px] rounded-md p-3 mt-3 flex mr-2 flex justify-center items-center"
        >
          <Img src="images/arrow-left.svg" className="h-8 w-8 mr-2 " />{" "}
          <span>Bank to login</span>
        </Link>
      </form>
    </AuthLayout>
  );
};

export default ForgotPassword;
