import React, { useState } from "react";
import { CloseSVG } from "assets/images";
import { Button } from "components";
import { Heading } from "components/Heading";
import { Img } from "components/Img";
import { Input } from "components/Input";
import AddOrganization from "components/addOrg";
import Paginator from "components/pagination";
import ViewOrganization from "modals/viewOrganization";
import useStore from "zustandState";




const Organization = ({isOpen, setIsOpen}) => {

  const [currentPage, setCurrentPage] = useState(1);
  const [searchBarValue, setSearchBarValue] = React.useState("");
  
  const [view, setView] = useState(false);
  const [currentData, setCurrentData] = useState<any>({});
  const organizations = useStore((state) => state.organizations);
  

  const handleView = (data) => {
    setCurrentData(data);
    setView(true);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const itemsPerPage = 5;
  const offset = (currentPage - 1) * itemsPerPage;
  const currentPageData = organizations.reverse().slice(offset, offset + itemsPerPage);
 

  return (
    <>
   
        <ViewOrganization isOpen={view} setIsOpen={setView} data={currentData} />
   

        <AddOrganization isOpen={isOpen} setIsOpen={setIsOpen}/>
   

      <div className="flex w-[100%] sm:w-full p-4 gap-8">
        <div className="w-6/12">
          <h2 className="text-lg text-bold">Organization</h2>
        </div>
        <div className="md:hidden w-6/12 flex justify-end gap-4">
    {
      ["Entities","Users", "Sensor", "Operation"].map((item)=>{
        return(
          <div
            className={`w-auto flex justify-evenly items-center p-3 hover:bg-gray-100 rounded-md cursor-pointer`}
          >
            <p>{item}</p>
            
          </div>
        )
      })
    }
    
        </div>
      </div>

      <div className="flex md:flex-wrap w-full gap-4">
        <div className=" sm:w-full md:w-[48%] w-1/4 h-[150px] rounded-lg border-[1.2px] border-gray-100 flex flex-col justify-evenly p-5">
          <h2 className="self-center text-lg">Number of organization</h2>
          <h2 className="self-center text-lg">25</h2>
        </div>

        <div className="sm:w-full md:w-[48%] w-1/4 h-[150x] sm:h-auto rounded-lg border-[1.2px] border-gray-100 flex flex-col justify-evenly ">
          <h2 className="self-center text-lg">Total emission in KG of CO2</h2>
          <div className="w-full flex justify-center item-center text-lg">
            <Img src="images/pie1.png" alt="pie" className="w-[35%]" />
          </div>
        </div>

        <div className="sm:w-full md:w-[48%] w-1/4 h-[150px] sm:h-auto rounded-lg border-[1.2px] border-gray-100 flex flex-col justify-evenly ">
          <h2 className="self-center text-lg">Percentage Reduction</h2>
          <div className="w-full flex justify-center item-center text-lg">
            <Img src="images/pie2.png" alt="pie" className="w-[35%] " />
          </div>
        </div>
        <div className="sm:w-full md:w-[48%] w-1/4 h-[150px] sm:h-auto rounded-lg border-[1.2px] border-gray-100 flex flex-col justify-evenly ">
          <h2 className="self-center text-lg">Economy</h2>
          <div className="w-full flex justify-center item-center text-lg">
            <Img src="images/pie3.png" alt="pie" className="w-[35%]" />
          </div>
        </div>
      </div>

      {/* Search and filter section */}
      <div className="flex w-full sm:flex-wrap gap-5 ">
        <div className="sm:w-full w-7/12">
          {" "}
          <Input
            color="white_A700"
            size="lg"
            variant="fill"
            shape="round"
            name="search"
            placeholder={`Search`}
            value={searchBarValue}
            onChange={(e: string) => setSearchBarValue(e)}
            prefix={
              <Img
                src="images/img_search.svg"
                alt="search"
                className="cursor-pointer"
              />
            }
            suffix={
              searchBarValue?.length > 0 ? (
                <CloseSVG
                  onClick={() => setSearchBarValue("")}
                  fillColor="#667084ff"
                />
              ) : null
            }
            className="h-12 p-4"
          />
        </div>
        <div className="sm:w-full w-5/12 flex gap-2">
          <div className="w-2/4 flex justify-evenly items-center rounded-md ">
          <Button
              color="blue_gray_800_02"
              size="lg"
              variant="fill"
              shape="round"
              className=" font-bold p-6"
              onClick={() => setIsOpen(true)}
            >
              Add Organization
            </Button>
          </div>
          <div className="w-2/4 flex justify-evenly items-center rounded-md border-[1.5px] border-gray-100 ">
            <Img src="images/exportIcon.png" className="w-6" /> <p>Export</p>
          </div>
          <div className="w-2/4 flex justify-evenly items-center rounded-md border-[1.5px] border-gray-100 ">
            <Img src="images/filter.png" className="w-6" /> <p>Filter By</p>
          </div>
        </div>
      </div>


      <div className="overflow-x-auto w-full border-[1.4px] border-gray-100 rounded-sm">
        <table className="w-full bg-white">
          <thead>
            <tr>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                <input type="checkbox" className="rounded-md" />
              </th>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Entity
              </th>
              <th className="px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                {" "}
                Company
              </th>
              <th className="px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                {" "}
                Monthly Issue
              </th>
              <th className="px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Percentage(%)
              </th>
              <th className="px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Status
              </th>
              <th className="px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Statistics
              </th>
              <th className="px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                action
              </th>
            </tr>
          </thead>
          <tbody>
            {organizations && organizations.length ? (
              currentPageData.map((item) => (
                <tr key={item.id}>
                  <td className="pl-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <input
                      type="checkbox"
                      name="organ"
                      className="rounded-md"
                    />
                  </td>
                  <td className="pr-2 py-4 whitespace-no-wrap border-b border-gray-200">
                    {" "}
                    <div className="flex justify-center items-center w-[72%] gap-2.5">
                      <div className="h-[30px] w-[50px]">
                        <Img
                          src="images/lady.png"
                          alt="image_one"
                        />
                      </div>
                      <div className="flex flex-col self-start items-start ">
                        <Heading size="xs" as="p" className="!text-gray-9text1">
                          {item.organization_name}
                        </Heading>
                        <p className="!text-blue_gray-600">
                          @Tannerfisher@gmail.com
                        </p>
                      </div>
                    </div>
                  </td>
                  <td className="px-2 py-4 whitespace-no-wrap border-b border-gray-200">
                    {item.nb_employees}
                  </td>
                  <td className="px-2 py-2 whitespace-no-wrap border-b border-gray-200">
                    <Img src="images/slider.png" className="w-14" />
                  </td>

                  <td className="px-2 py-2 whitespace-no-wrap border-b border-gray-200">
                    20%
                  </td>
                  <td className="px-2 py-4 whitespace-no-wrap border-b border-gray-200">
                    {item.status ? (
                      <Img src="images/inactive.png" className="w-14" />
                    ) : (
                      <Img src="images/active.png" className="w-14" />
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    {item.status ? (
                      <Img src="images/decrease.png" className="w-14" />
                    ) : (
                      <Img src="images/increase.png" className="w-14" />
                    )}
                  </td>
                  <td
                    className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 cursor-pointer"
                    onClick={() => handleView(item)}
                  >
                    View
                  </td>
                </tr>
              ))
            ) : (
              <div className=" sm:p-2 p-4 text-center w-full font-bold text-yellow-800 sm:text-[10px]">Data not found, kindly add an entity.</div>
            )}
          </tbody>
    
        </table>
      </div>
      <Paginator
        totalPages={Math.ceil(organizations.length / itemsPerPage)}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    

    </>
  );
};

export default Organization;
