import React from "react";
import { Button, Heading, Img } from "../../components";
import { default as ModalProvider } from "react-modal";


interface Props {
  className?: string;
  isOpen: boolean;
  setIsOpen:React.Dispatch<React.SetStateAction<boolean>>,
  title:string,
  subtitle?:string,
  img?:string,
  children:React.ReactNode
}

export default function CreateModal({ title, isOpen, setIsOpen, subtitle,img, children, ...props }: Props) {
  return (
    <ModalProvider {...props} appElement={document.getElementById("root")!} isOpen={isOpen} className="w-screen h-screen overflow-y-auto">
      <div className="flex  sm:w-full w-full justify-center sm:h-auto h-screen sm:py-0 py-4" style={{backgroundColor:"rgba(0,0,0,.5)"}}>
        <div className="flex flex-col h-auto items-center sm:w-full md:w-[400px] w-[500px] sm:m-0 h-auto pb-6 md:ml-0 sm:pb-5 bg-white-A700 sm:rounded-[0.2px] rounded-[12px] overflow-y-auto">
          {/* header */}
             <div className="self-stretch">
              <div className="h-[80px] relative ">
                <div className="justify-center h-[90px] w-full left-0 bottom-0 right-0 top-0 m-auto bg-gray-100_01  rounded-t-xl absolute" />
                <div className="flex sm:flex-col justify-center items-start sm:w-full w-[84%] gap-[17px] bottom-[20%] right-0 left-0 m-auto absolute">
                 { img && <Img src={img} alt="avatar_one" className="h-[48px] w-[48px] rounded-[50%]" />}
                  <div className="flex flex-col mb-0.5 gap-[3px] sm:p-5 flex-1 w-full">
                    <div className="flex justify-between items-center gap-5 w-full ">
                      <Heading size="lg" as="h1">
                        {title}
                      </Heading>
                      <Button size="sm" variant="fill" shape="circle" className="w-[30px]" onClick={()=>setIsOpen(false)}>
                        <Img src="images/img_frame_143.svg" />
                      </Button>
                    </div>
                    <p className="!text-blue_gray-800_02">
                    { img &&  subtitle}
                    </p>
                  </div>
                </div>
              </div>
        
          </div>
          
          {/* content */}
          <div className="flex flex-col w-full justify-center h-auto px-10 pt-3">
            {children}
          </div>
    
        </div>
      </div>
    </ModalProvider>
  );
}
