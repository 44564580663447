import React from "react";

const shapes = {
  square: "rounded-[0px]",
  round: "rounded-lg",
} as const;
const variants = {
  fill: {
    gray_100: "bg-gray-100 text-blue_gray-800_02",
    gray_50_01: "bg-gray-50_01 text-blue_gray-500_01",
    white_A700: "bg-white-A700 shadow-xs",
  },
  outline: {
    gray_200_01: "border-gray-200_01 border border-solid text-blue_gray-800_02",
  },
} as const;
const sizes = {
  xs: "h-[36px] pl-2 pr-[35px] text-sm",
  md: "h-[51px] pl-3.5 pr-[35px] text-base",
  sm: "h-[44px] pl-3.5 pr-[35px] text-base",
  lg: "h-[60px] pl-4 pr-[35px] text-base",
} as const;

type InputProps = Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  "size" | "prefix" | "type" | "onChange"
> &
  Partial<{
    className: string;
    name: string;
    placeholder: string;
    type: string;
    label: string;
    prefix: React.ReactNode;
    suffix: React.ReactNode;
    onChange: Function;
    shape: keyof typeof shapes;
    variant: keyof typeof variants;
    size: keyof typeof sizes;
    color: string;
    error?:string
  }>;
const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className = "",
      name = "",
      placeholder = "",
      type = "text",
      children,
      label = "",
      prefix,
      suffix,
      onChange,
      shape,
      variant = "outline",
      size = "lg",
      color = "gray_200_01",
      ...restProps
    },
    ref,
  ) => {
    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
      if (onChange) onChange(e?.target?.value);
    };

    return (
      <>
        <div
          className={`${className} flex items-center justify-center text-base border border-solid rounded-lg w-full  ${(shape && shapes[shape]) || ""} ${variants[variant]?.[color as keyof (typeof variants)[typeof variant]] || variants[variant] || ""} ${sizes[size] || ""}`}
        >
          {!!label && label}
          {!!prefix && prefix}
          <input ref={ref} type={type} name={name} className="w-full" onChange={handleChange} placeholder={placeholder} {...restProps} />
          {!!suffix && suffix}
        </div>
      </>
    );
  },
);

export { Input };
