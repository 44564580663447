import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import CustomInput from "components/customizedInput";
import { Button } from "components";

import AuthLayout from "authlayout";

const ResetPasssword = () => {

  

  let indicator =[]
  console.log(indicator)
  const checkPasswordStrength = (password) => {
    let strength = 0;
    const requirements = [
      /[a-z]+/, // Lowercase letters
      /[A-Z]+/, // Uppercase letters
      /[0-9]+/, // Numbers
      /[@$!%*?&]+/, // Symbols
    ];
  
    requirements.forEach((regex, pos) => {
      if (regex.test(password)) {
        strength += 1;
        if(!indicator.includes(pos)){
          indicator.push(pos)
        }
      }
    });
  
    return strength;
  };


  const formik = useFormik({
    initialValues: {
      confirmPassword: "",
      password: "",
    },
    validationSchema: yup.object({
      confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Please confirm your password"),
      
      password: yup
        .string()
        .min(8, "Password must be at least 8 characters")
        .required("Password is required"),
    }),
    onSubmit: async (values) => {
      try {
        await axios.post("/api/signin", values);
   
      } catch (err) {
 
      }
    },
  });

  return (
    <AuthLayout>
      <form
        onSubmit={formik.handleSubmit}
        className="w-full h-full sm:p-6 md:p-3 p-5 flex flex-col items-center gap-2"
      >
        <div className="w-full">
          <h1 className="sm:text-[1.5rem] text-[1.8rem] font-semibold mulish text-center">
            Set new password
          </h1>
          <p className="sm:text-[12px] pb-4 text-center sm:text[10px] text-[14px]">
            Your new password must be different to the previous used passwords.
          </p>
        </div>
        <div className="w-full">
          <label htmlFor="password" className="block mb-2">
            Password
          </label>
          <CustomInput
            name="password"
            type="password"
            bgColor="bg-white"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            placeholder="Enter your password"
            error={formik.touched.password && formik.errors.password}
            icon="images/lock.png"
          />
        </div>
        <div className="w-full">
          <label htmlFor="password" className="block mb-2">
            Confirm Password
          </label>
          <CustomInput
            name="confirmPassword"
            type="password"
            bgColor="bg-white"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.confirmPassword}
            placeholder="Enter your password again"
            error={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
            icon="images/lock.png"
          />
        </div>
        <div className="flex justify-between gap-x-2 pb-3  items-center w-full">
            <div
              className={`h-1 w-1/4 ${
                checkPasswordStrength(formik.values.password) > 0
                  ? "bg-yellow-100"
                  :"bg-[#e0e3e9]"
              } `}
            ></div>
            <div
              className={`h-1 w-1/4 ${
                checkPasswordStrength(formik.values.password) > 1
                  ? "bg-yellow-200"
                  : "bg-[#e0e3e9]"
              } `}
            ></div>
            <div
              className={`h-1 w-1/4 ${
                checkPasswordStrength(formik.values.password) > 2
                  ? "bg-yellow-300"                  
                  : "bg-[#e0e3e9]"
              } `}
            ></div>
            <div
              className={`h-1 w-1/4 ${
                checkPasswordStrength(formik.values.password) >= 3
                  ? "bg-green-400"
                  : "bg-[#e0e3e9]"
              } `}
            ></div>
          </div>
          {formik.values.password && !formik.errors.password && (
            <p className="w-full font-bold text-sm text-green-400 flex justify-end">
              Strong
            </p>
          )}
          <div className="text-[12px] flex flex-col w-full text-[#e0e3e9] pb-2 ">
            <li
              className={`${
                formik.values.password.match(/[A-Z]+/) && formik.values.password.match(/[a-z]+/) ? "text-gray-700" : ""
              } `}
            >
              Upper and lowercase letters
            </li>
            <li
              className={`${
                formik.values.password.length >= 8 ? "text-gray-700" : ""
              } `}
            >
              More than 8 characters
            </li>
            <li
              className={`${
                formik.values.password.match(/[@$!%*?&]+/)
                  ? "text-gray-700"
                  : ""
              } `}
            >
              Contains a number or symbol
            </li>
          
        </div>

        <Button
          type="submit"
          color="blue_gray_800_02"
          size="4xl"
          variant="fill"
          shape="round"
          className="w-full bg-blue_gray-800_02 text-white rounded-md p-2"
        >
          {formik.isSubmitting ? "Submitting ..." : "Reset Password"}
        </Button>
      </form>
    </AuthLayout>
  );
};

export default ResetPasssword;
