import React, { SetStateAction, useState } from "react";
import { Button, Heading, Text, Img } from "../../components";
import CreateModal from "modals/customModal";
import * as yup from 'yup'
import { useFormik } from "formik";
import EditOrganization from "components/editOrg/editOrganizatin";

interface Props {
  className?: string;
  isOpen:boolean,
  setIsOpen:React.Dispatch<SetStateAction<boolean>>
  data?:IData
}

interface IData {
  CO2_emission: string;
  activity: string;
  city: string;
  country: string;
  end_date: string;
  id: number;
  nb_employees: number;
  nb_entities: number;
  organization_name: string;
  start_date: string;
  status: boolean;
}

export default function ViewOrganization({ isOpen, setIsOpen, data, ...props }: Props) {
  const [edit, setEdit] = useState(false);

  if(!data) return 
  const { 
  CO2_emission,
  activity,
  city,
  country,
  nb_employees,
  nb_entities,
  organization_name,
} =data

  return (
    <CreateModal isOpen={isOpen} setIsOpen={setIsOpen} title={organization_name} img="images/lady.png" >
     { !edit ? <>
      <div className="flex md:flex-row sm:flex-col w-full gap-5 p-2 bg-white-A700">
      <div className="flex flex-col items-start w-full sm:w-full gap-2 border-gray-200 border-r border-solid">
        <Heading as="h2" className="!text-blue_gray-600">
          No. of Entities
        </Heading>
        <Heading size="xl" as="h3" className="h-[30px]">
          {nb_entities}
        </Heading>
      </div>
      <div className="flex flex-col items-start w-full gap-1.5 border-gray-200 border-r border-solid">
        <Heading as="h2" className="!text-blue_gray-600">
          No. of CO2
        </Heading>
        <Heading size="xl" as="h3" className="h-[30px]">
          {CO2_emission}
        </Heading>
      </div>
      <div className="flex flex-col items-start gap-1.5">
        <Heading as="h2" className="!text-blue_gray-600">
          No. of employees
        </Heading>
        <Heading size="xl" as="h3">
          {nb_employees}
        </Heading>
      </div>
    </div>
    <div className="flex flex-col w-full gap-6 mb-3">
      <div className=" border-gray-200_01 border border-solid  p-[5px] rounded-md flex flex-col pl-4 pr-[35px] sm:pt-5 sm:pr-5 text-blue_gray-800_02 text-left text-base font-semibold bg-gray-50">
       <p className="py-2">Email</p>
       <p  className="pb-2"> example@gmail.com</p>
      </div>
        <div className=" border-gray-200_01 border border-solid  p-[5px] rounded-md flex flex-col pl-4 pr-[35px] sm:pt-5 sm:pr-5 text-blue_gray-800_02 text-left text-base font-semibold bg-gray-50">
       <p className="py-2">Organization</p>
       <p className="pb-2" > {organization_name}</p>
      </div>
        <div className=" border-gray-200_01 border border-solid  p-[5px] rounded-md flex flex-col pl-4 pr-[35px] sm:pt-5 sm:pr-5 text-blue_gray-800_02 text-left text-base font-semibold bg-gray-50">
       <p className="py-2">Address</p>
       <p  className="pb-2"> {city}, {country}</p>
      </div>
      <div className="border-gray-200_01 border border-solid   p-[5px] rounded-md flex flex-col pl-4 pr-[35px] sm:pt-5 sm:pr-5 text-blue_gray-800_02 text-left text-base font-semibold bg-gray-50">
       <p className="py-2">Address</p>
       <p  className="pb-2"> {activity}</p>
      </div>
      <Button
        color="blue_gray_800_02"
        size="4xl"
        variant="fill"
        shape="round"
        className="sm:px-5 font-bold"
        onClick={()=>setEdit(true)}
      >
        Edit Information
      </Button>
    </div>
     </>:<>
     <EditOrganization data={data} setIsOpen={setIsOpen}/>
     
     </>}

    

    </CreateModal>
  );
}












