import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js/auto';

interface BarChartProps {
  data: number[];
  labels: string[];
  type:string
}

const BarChart: React.FC<BarChartProps> = ({ data, labels, type}) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  let options:any = type === "bar" ? {
    type: 'bar',
    data: {
      labels: labels,
      datasets: [{
        label: 'Emission',
        data: data,
        backgroundColor: 'rgb(52 64 84)',
        borderRadius:30,
  
      }]
    },
    options: {
      scales: {
        y: {
          beginAtZero: true
        }
      },
      title: {
        display: true,
        text: 'Chart Title',
      }
    }
  } :type === "line2" ? {
    type: 'line',
    data: {
      labels: labels,
      datasets: [{
        data: data,
        backgroundColor: 'rgb(52 64 84)',
        borderColor: '#05021f',
        borderWidth: 1,
        lineTension:0.3
      },
      {
        data: [20,30,40,50,100],
        backgroundColor: 'rgb(52 64 84)',
        borderColor: '#c2bebf',
        borderWidth: 1,
        lineTension:0.4
      }]
    },
    options: {
      scales: {
        y: {
          beginAtZero: true
        }
      }
    }
  }:type === "bar2" ?{
    type: 'bar',
    data: {
      labels: labels,
      datasets: [{
        label: 'Goals',
        data: data,
        backgroundColor: '#f7f3f4',
        borderRadius:30,
        marginBottom:4,
        lineTension: 0.4
      },
      {
        label: 'Progress',
        data: data,
        backgroundColor: 'rgb(52 64 84)',
        borderRadius:50,
        marginBottom:4,
        lineTension: 0.4
      }]
    },
    options: {
      scales: {
        y: {
          beginAtZero: true
        }
      }
    }
  }:{
    type: 'line',
    data: {
      labels: labels,
      datasets: [{
        data: [2, 10, 40, 36, 50],
        backgroundColor: 'rgb(52 64 84)',
        borderRadius:50,
        marginBottom:4,
        lineTension: 0.3
      },
      {
        data: data,
        backgroundColor: 'rgba(59, 42, 87, 0.2)',
        borderRadius:50,
        marginBottom:4,
        lineTension: 0.4
      }
     ]
    },
    options: {
      scales: {
        y: {
          beginAtZero: true
        }
      }
    }
  }

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');
      if (ctx) {
        new Chart(ctx, options );
      }
    }
  }, [chartRef, data, labels]);

  return <canvas ref={chartRef}></canvas>;
};

export default BarChart;

