import React, { SetStateAction } from "react";
import { Button, Img, Heading } from "./..";
import { IMenusEnum } from "data/data";

interface Props {
  className?: string;
  displayMenu: boolean;
  menus?:string,
  setDisplayMenu: React.Dispatch<SetStateAction<boolean>>;
}

export default function Header({
  displayMenu,
  setDisplayMenu,
  menus,
  ...props
}: Props) {

  console.log('menus', menus)
  return (
    <header  {...props}>
      <div className="flex w-full items-center justify-start max-w-[920px] ">

    <span className="sm:block md:block hidden ">
    <Button
            size="2xl"
            variant="fill"
            shape="round"
            className=" w-[48px] mr-4 border-blue_gray-100_01"
            onClick={() => setDisplayMenu(!displayMenu)}
          >
            <Img src="images/menu.svg" />
          </Button>
    </span>
       

        <Heading size="2xl" as="h4" className="!text-gray-900">
          Dashboard
        </Heading>
      </div>
        <div className="flex items-center flex-end gap-2">
        <Button
        size="2xl"
        variant="fill"
        shape="round"
        className="w-[48px] border-blue_gray-100_01 border border-solid"
      >
        <Img src="images/img_button.svg" />
      </Button>
      <Button
        size="2xl"
        variant="fill"
        shape="round"
        className="w-[48px] border-blue_gray-100_01 border border-solid"
      >
        <Img src="images/img_button_blue_gray_500_02.svg" />
      </Button>
     {menus === IMenusEnum.Emission &&  <Button
        size="2xl"
        variant="fill"
        color="blue_gray_800_02"
        shape="round"
        className="bg-blue_gray-800_02 text-white sm:hidden block"
      >
        New Calculations
      </Button>}
        </div>
    </header>
  );
}
