export interface IEntityData{
  city:number,
  agencies:number,
  employee:number,
  email:string,
  name:string,
  address:string,
  activities:string[]

}
export const entityData: IEntityData={
        city:32,
        agencies:40,
        employee:600,
        email:"Banjokodamilola1@gmail.com",
        name:" Casablanca Organization",
        address:"123, Rue de I’Innovation, Techville City, Techland Country",
        activities:["Development and manufacturing of innovative technological solution"]
  }


  export const dummyUser = {
    email:"bompay@gmail.com",
    password:"Bompay@1234"
  }


  export const chartData ={
    data:[10, 30, 20, 39, 40],
    labels: ['A', 'B', 'C', 'D', 'E'],
    data2:[5000, 25000, , 30000, 36000, 40000],
    labels2: ['2020', '2021', '2022', '2023', '2024'],

}