import { ApiService } from "axiosRequest";
import { Button } from "components/Button";
import CustomInput from "components/customizedInput";
import { useFormik } from "formik";
import React from "react";
import { toast } from "react-toastify";
import * as yup from "yup";

export default function EditOrganization({ data, setIsOpen }) {


  const formik = useFormik({
    initialValues: {
        nb_employees: data.nb_employees || "",
        activity: data.activity || "",
        CO2_emission:  data.CO2_emission || "",
        city: data.city || "",
        nb_entities: data.nb_entities || "",
        country: data.country || ""
    },
    validationSchema: yup.object().shape({
    nb_employees: yup.string().required(),
    CO2_emission: yup.number().required().positive().integer(),
      activity: yup.string().required(),
      country: yup.string().required(),
      city: yup.string().required(),
      nb_entities: yup.number().required().positive().integer()
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const res=   await new ApiService().editOrganization({...values, organizationId:data.id})
      if(!res.error){
      setIsOpen(false)
      toast.success("Organization updated successfully");
      }
    },
  });

  const fields = [
    {
      type: "number",
      placeholder: "Enter no of employees ",
      label: "Number of Employee",
      name: "nb_employees",
    },
    {
      type: "text",
      placeholder: `Enter Activity`,
      label: "Activity",
      name: "activity",
    },
    {
        type: "number",
        placeholder: `Enter No of Entity `,
        label: "Entities",
        name: "nb_entities",
      },
    {
        type: "number",
        placeholder: `Enter CO2 `,
        label: "Number of CO2",
        name: "CO2_emission",
      },
      {
        type: "text",
        placeholder: `Enter Country `,
        label: "Country",
        name: "country",
      },
      {
        type: "text",
        placeholder: `Enter City `,
        label: "City",
        name: "city",
      },
    
  ];

  return (
    <form onSubmit={formik.handleSubmit} className="">
      
      {fields.map((field) => (
        <div className="mt-2 ">
          <label>{field.label}</label>
          <CustomInput
            key={field.name}
            placeholder={field.placeholder}
            name={field.name}
            value={formik.values[field.name]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[field.name] && formik.errors[field.name]}
            type={field.type}
          />
        </div>
      ))}

      <Button
        color="blue_gray_800_02"
        size="4xl"
        variant="fill"
        shape="round"
        className="sm:px-5 font-bold min-w-[100%] self-center mt-4"
        disabled={formik.isSubmitting}
        type="submit"
      >
        {formik.isSubmitting ? "Saving..." : "Save Information"}
      </Button>
    </form>
  );
}
