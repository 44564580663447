import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import CreateModal from "modals/customModal";
import CustomInput from "components/customizedInput";
import { Button } from "components/Button";
import { ApiService } from "axiosRequest";
import { toast } from "react-toastify";
import { SelectBox } from "components/SelectBox";
import useStore from "zustandState";

interface Props {
  className?: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;

}

const CreateEntityForm = ({ isOpen, setIsOpen, ...props }: Props) => {
  const organizations = useStore((state) => state.organizations);
  const dropDownOptions:any = organizations.map((item)=>{return {value:item.id, label:item.organization_name}})
  const [selectedOrg,setSelectorOrg] =useState({})
  
  const handleSelect=(e)=>{
    setSelectorOrg(e.value)
  }
  const formik = useFormik({
    initialValues: {
      entityName: "",
      nbLocal: "",
      nbEmployees: "",
      activity: ""
  
     
    },
    validationSchema: yup.object().shape({
      entityName: yup.string().required(),
      nbLocal: yup.number().required().positive().integer(),
      nbEmployees: yup.number().required().positive().integer(),
      activity: yup.string().required(),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
     const res = await new ApiService().addEntity({...values, organizationId:selectedOrg.toString(), entityId:"123DTS"})
     if(!res.error){
     setIsOpen(false)
     toast.success("Entity Added successfully")
     }
    },
  });

  const fields = [
    { type:'text', placeholder: "Enter Entity Name",  label: "Entity Name", name: "entityName" },
    { type:'number', placeholder:`Enter NB Local`, label: "Type", name: "nbLocal" },
    { type:'number', placeholder:`Enter number of employees`, label: "Number of Employees", name: "nbEmployees" },
    { type:'text', placeholder:`Enter activity`, label: "Activity", name: "activity" },

   
  ];

  return (
    <CreateModal title="Create Entity" isOpen={isOpen} setIsOpen={setIsOpen} {...props}>
      <form onSubmit={formik.handleSubmit} className="mt-4"> 
      <div className="my-2 ">
        <label className="py-2">Organization</label>
      <SelectBox className="border-[1.2px] rounded-md mt-2" options={dropDownOptions} onChange={(e)=>handleSelect(e)}/>
      </div>
        {fields.map((field) => (
         <div className="my-6 ">
           <label>{field.label}</label>
          <CustomInput
            key={field.name}
            placeholder={field.placeholder}
            name={field.name}
            value={formik.values[field.name]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[field.name] && formik.errors[field.name]}
            type={field.type}

          />
         </div>
        ))}

        <Button
          color="blue_gray_800_02"
          size="4xl"
          variant="fill"
          shape="round"
          className="sm:px-5 font-bold min-w-[100%] self-center mt-4"
          disabled={formik.isSubmitting}
          type="submit"
        >
          {formik.isSubmitting ? "Saving..." : "Create Entity"}
        </Button>
      </form>
    </CreateModal>
  );
};

export default CreateEntityForm;







