import React, { SetStateAction, useState } from "react";
import { Button, Heading, Text, Img } from "../../components";
import CreateModal from "modals/customModal";
import useStore from "zustandState";
import EditEntity from "components/editEntity";

interface Props {
  className?: string;
  isOpen:boolean,
  setIsOpen:React.Dispatch<SetStateAction<boolean>>
  data?:any
}



export default function ViewEntity({ isOpen, setIsOpen, data, ...props }: Props) {
  const [edit, setEdit] = useState(false);
  const organizations = useStore((state)=>state.organizations)

  
  const { 
    organizationId,
    id,
    nbEmployees,
    nbLocal,
    activity,
  } =data
  
  const currentOrganization = organizations.find(item=>item.id === Number(organizationId) )
  const {organization_name } = currentOrganization

  const formatedData = {...data, organization_name}
  return (
    <CreateModal isOpen={isOpen} setIsOpen={setIsOpen} title={organization_name} img="images/lady.png" >
   
      {!edit ? <div className="flex flex-col w-full h-full py-10 justify-between gap-6 mb-3">
        <div className=" border-gray-200_01 border border-solid  p-[5px] rounded-md flex flex-col pl-4 pr-[35px] sm:pt-5 sm:pr-5 text-blue_gray-800_02 text-left text-base font-semibold bg-gray-50">
         <p className="py-2">Email</p>
         <p  className="pb-2"> {organization_name}</p>
        </div>
          <div className=" border-gray-200_01 border border-solid  p-[5px] rounded-md flex flex-col pl-4 pr-[35px] sm:pt-5 sm:pr-5 text-blue_gray-800_02 text-left text-base font-semibold bg-gray-50">
         <p className="py-2">Organization</p>
         <p className="pb-2" > {organization_name}</p>
        </div>
          <div className=" border-gray-200_01 border border-solid  p-[5px] rounded-md flex flex-col pl-4 pr-[35px] sm:pt-5 sm:pr-5 text-blue_gray-800_02 text-left text-base font-semibold bg-gray-50">
         <p className="py-2">No Employee</p>
         <p  className="pb-2">  {nbEmployees}</p>
        </div>
        <div className=" border-gray-200_01 border border-solid  p-[5px] rounded-md flex flex-col pl-4 pr-[35px] sm:pt-5 sm:pr-5 text-blue_gray-800_02 text-left text-base font-semibold bg-gray-50">
         <p className="py-2">No of Entities</p>
         <p  className="pb-2">  {nbLocal}</p>
        </div>
        <div className="border-gray-200_01 border border-solid   p-[5px] rounded-md flex flex-col pl-4 pr-[35px] sm:pt-5 sm:pr-5 text-blue_gray-800_02 text-left text-base font-semibold bg-gray-50">
         <p className="py-2">Address</p>
         <p  className="pb-2"> {activity}</p>
        </div>
    
        <Button
          color="blue_gray_800_02"
          size="4xl"
          variant="fill"
          shape="round"
          className="sm:px-5 font-bold"
          onClick={()=>setEdit(true)}
        >
          Edit Information
        </Button>
      </div>: <EditEntity data={formatedData} setIsOpen={setIsOpen}/>}


    </CreateModal>
  );
}
