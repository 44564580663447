import React, { SetStateAction, useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";


interface DLayout {
    children:React.ReactNode,
    menus:string,
    toggleMenu:React.Dispatch<SetStateAction<string>>
}

export default function DashboardLayout({menus, toggleMenu, children}:DLayout) {
  const [displayMenu, setDisplayMenu] = useState(false)


  return (
    <>
      <Helmet>
        <title>FootPrint</title>
        <meta
          name="description"
          content="Web site created using create-react-app"
        />
      </Helmet>
      <div className="flex md:flex-col h-auto justify-center w-full bg-white-A700 relative">
    

        {
          displayMenu  ?   <Sidebar
          className="h-screen flex border-gray-200 border-[1.2px] flex-col top-0 border-gray-300 z-10 border-solid bg-white-A700 !sticky"
          toggleMenu={toggleMenu}
          menus={menus}
          setDisplayMenu={setDisplayMenu}
        />:<Sidebar
        className="h-screen absolute border-gray-200 border-[1.2px] top-0 md:hidden flex flex-col w-[314px] h-auto top-0 border-gray-300 border-solid bg-white-A700 !sticky"
        toggleMenu={toggleMenu}
        menus={menus}
        setDisplayMenu={setDisplayMenu}
      />
      
        }
        <div className="flex flex-col w-full items-center pb-[73px] sticky ">
          <Header 
          className="bg-white-A700 flex justify-between items-center gap-4 p-6 sm:py-5 w-full border-gray-300 border-b border-solid z-[90] sticky top-0" 
          displayMenu={displayMenu}
          setDisplayMenu={setDisplayMenu}
          menus={menus}
          />
          <div className="w-full">
          {children}
          </div>
        </div>
        
      </div>
    </>
  );
}
