import React, { ReactNode } from "react";


import MobileHeader from "components/mobileHeader";

interface Props{
    children:ReactNode
}
const AuthLayout = ( {children }:Props) => {
  
  return (
    <div className="sm:h-screen sm:w-full h-screen w-full flex flex-col items-center  md:justify-center md:justify-center justify-center items-center " >
      <div className="sm:h-[10%] w-full">
        <MobileHeader />
      </div>
      <div className="sm:h-[90%] md:w-[400px] w-[500px] sm:p-4 md:p-6 p-10 flex flex-col items-center">
            { children }
      </div>
    </div>
  );
};

export default AuthLayout;
