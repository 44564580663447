import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import CreateModal from "modals/customModal";
import CustomInput from "components/customizedInput";
import { Button } from "components/Button";
import { ApiService } from "axiosRequest";
import { toast } from "react-toastify";
import { SelectBox } from "components/SelectBox";

interface Props {
  className?: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const dropDownOptions = [
  {value:"admin", label:"Admin"},
  {value:"user",label:"User"}
]
const AddEmployee = ({ isOpen, setIsOpen, ...props }: Props) => {
  const [org, setOrg] = useState([])

  const fetchOrganization=async ()=>{
    try {
      const res = await new ApiService().getOrganizations()
      
      if(res){
        setOrg(res.rows)
      }

    } catch (error) {
      
    }
  }

  useEffect(()=>{
    fetchOrganization()
  },[isOpen])

  const orgDropdown = org.map(item=>{
    return {value:item.organization_name, label:item.organization_name}
  })

  const [role, setRole] = useState<{role:string, organisation_name:string}>({role:'', organisation_name:''})
  const handleSelect = (e)=>{
    setRole({...role, role:e.value})
  }

  const handleOrgSelect=(e)=>{
    setRole({...role, organisation_name:e.value})
  }

  const formik = useFormik({
    initialValues: {
      name:'',
      tel:'',
      email:'',
      password:'',
      address:'',
      deplacement:'',
      entity_name:''
    },
    validationSchema: Yup.object().shape({
        name: Yup.string().required(),
        tel: Yup.string().matches(/^\+\d{10,15}$/).required(),
        email: Yup.string().email().required(),
        password: Yup.string().min(8,"Minimum of 8 char").required(),
        address: Yup.string().required(),
        deplacement: Yup.string().required(),
        entity_name: Yup.string().required(),
    }),
    onSubmit: async (values, { setSubmitting }) => {
     const res = await new ApiService().addUser({...values, ...role, statut:"active",entity_id:"123BDC"})
     if(!res.error){
     setIsOpen(false)
     toast.success(res.message || "User added successfully")
     setSubmitting(false)
     setIsOpen(false)
     }
    },
  });

  const fields = [
    { type:'text', placeholder:`Enter full name`, label: "Full Name", name: "name" },
    { type:'email', placeholder:`Enter your Email`, label: "Email", name: "email" },
    { type:'text', placeholder:`Enter Entity Name`, label: "Entity", name: "entity_name" },
    { type:'text', placeholder:`Enter phone number`, label: "Phone Number", name: "tel" },
    { type:'text', placeholder:`Enter Address`, label: "Address", name: "address" },
    { type:'text', placeholder:`Enter Deplacement`, label: "Deplacement", name: "deplacement" },
    { type:'password', placeholder:`Enter password`, label: "Password", name: "password" },
  ];

  return (
    <CreateModal title="Add an Employee" isOpen={isOpen} setIsOpen={setIsOpen} {...props}>
      <form onSubmit={formik.handleSubmit} className="mt-2"> 

      <div className="my-2 ">
        <label className="py-2">Role</label>
          <SelectBox className="border-[1.2px] rounded-md mt-2" options={dropDownOptions} onChange={(e)=>handleSelect(e)}/>
      </div>

      <div className="my-2 ">
        <label className="py-2">Organization</label>
          <SelectBox className="border-[1.2px] rounded-md mt-2" options={orgDropdown} onChange={(e)=>handleOrgSelect(e)}/>
      </div>

        {fields.map((field) => (
          <CustomInput
            key={field.name}
            placeholder={field.placeholder}
            name={field.name}
            value={formik.values[field.name]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[field.name] && formik.errors[field.name]}
            type={field.type}
          />
        ))}

   
        <Button
          color="blue_gray_800_02"
          size="4xl"
          variant="fill"
          shape="round"
          className="sm:px-5 font-bold min-w-[100%] self-center mt-4"
          disabled={formik.isSubmitting}
          type="submit"
        >
          {formik.isSubmitting ? "Saving..." : "Save Information"}
        </Button>
      </form>
    </CreateModal>
  );
};

export default AddEmployee;


