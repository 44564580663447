import { ApiService } from "axiosRequest";
import { Button } from "components/Button";
import { SelectBox } from "components/SelectBox";
import CustomInput from "components/customizedInput";
import { useFormik } from "formik";
import React from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import * as yup from "yup";

export default function EditSensor({ data, setIsOpen}) {
  const [selectedOrg, setSelectorOrg] = useState({});


  const handleSelect = (e) => {
    setSelectorOrg(e.value);
  };


  const formik = useFormik({
    initialValues: {
        model: data.model || "",
        libelle: data.libelle || "",
        record:  data.record || "",
        start_date: data.start_date || "",
        end_date: data.end_date || ""
    },
    validationSchema: yup.object().shape({
    model: yup.string().required(),
    record: yup.string().required(),
      libelle: yup.string().required('Label is required'),
      end_date: yup.date().required(),
      start_date: yup.date().required()
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
       const res =   await new ApiService().editSensor({...values, id:data.id})

       if(!res.error){
        setIsOpen(false)
      toast.success(res?.message ||  "Sensor updated successfully");
       }
    },
  });

  const fields = [
    { type:'text', placeholder:`Enter Record`, label: "Record", name: "record" },
    { type:'text', placeholder:`Enter model`, label: "Model", name: "model" },
    { type:'text', placeholder:`Enter Label`, label: "Label", name: "libelle" },
    { type:'date', placeholder:`Enter Start Date`, label: "Start Date", name: "start_date" },
    { type:'date', placeholder:`Enter End Date`, label: "End Date", name: "end_date" },


   
  ];

  return (
    <form onSubmit={formik.handleSubmit} className="mt-6">
      
      {fields.map((field) => (
        <div className="my-4 ">
          <label>{field.label}</label>
          <CustomInput
            key={field.name}
            placeholder={field.placeholder}
            name={field.name}
            value={formik.values[field.name]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[field.name] && formik.errors[field.name]}
            type={field.type}
          
          />
        </div>
      ))}

      <Button
        color="blue_gray_800_02"
        size="4xl"
        variant="fill"
        shape="round"
        className="sm:px-5 font-bold min-w-[100%] self-center mt-4"
        disabled={formik.isSubmitting}
        type="submit"
      >
        {formik.isSubmitting ? "Saving..." : "Save Information"}
      </Button>
    </form>
  );
}
