import React from "react";
import { useRoutes } from "react-router-dom";
import NotFound from "pages/NotFound";
import DASHBOARD from "pages/dashboard";
import Login from "pages/login";
import SignUp from "pages/signup";
import ForgotPassword from "pages/forgot";
import ResetPasssword from "pages/resetpassword";

const ProjectRoutes = () => {

  let element = useRoutes([
    {path:'/', element:<Login/>},
    { path: "/dashboard", element: <DASHBOARD /> },
    {path:'/signup', element:<SignUp/>},
    {path:'/forgot', element:<ForgotPassword/>},
    {path:'/reset-password', element:<ResetPasssword/>},

    { path: "*", element: <NotFound /> },
  ]);

  return element;
};

export default ProjectRoutes;
