import { CloseSVG } from "assets/images";
import { Heading } from "components/Heading";
import { Img } from "components/Img";
import { Input } from "components/Input";
import { IMenusEnum } from "data/data";
import React, { useState, useEffect } from "react";
interface props{
    menu:string
}
const Operation = ({menu}:props) => {
  const [data, setData] = useState([{}]);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchBarValue, setSearchBarValue] = React.useState("");
  const [view, setView] = useState(false)

  console.log(view)

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch("https://api.example.com/data");
      const json = await response.json();
      setData(json);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage);
  };

  const itemsPerPage
   = 2;
  const offset = (currentPage - 1) * itemsPerPage;
  const currentPageData = data.slice(offset, offset + itemsPerPage);

  return (
    < >
     <div className="flex w-[100%] sm:w-full p-6 gap-8">
        <div className="w-8/12">
          <h2 className="text-lg text-bold">Casablanca Organization</h2>
        </div>
        <div className="md:hidden w-4/12 flex justify-end gap-2">
          <div className="w-auto flex justify-evenly items-center p-2 hover:bg-gray-100 rounded-md ">
             <p>Entities</p>
          </div>
          <div className="w-auto flex justify-evenly items-center p-2 hover:bg-gray-100 rounded-md ">
            <p>Users</p>
          </div>
          <div className="w-auto flex justify-evenly items-center p-2 hover:bg-gray-100 rounded-md ">
            <p>Sensors</p>
          </div>
          <div className={`w-auto flex justify-evenly items-center p-2 hover:bg-gray-100 rounded-md ${menu===IMenusEnum.Operation && "bg-gray-100" }`}>
            <p>Operation</p>
          </div>
        </div>
      </div>
    
      {/* Search and filter section */}
      <div className="flex w-full sm:flex-wrap gap-5 ">
        <div className="sm:w-full w-7/12">
          {" "}
          <Input
            color="white_A700"
            size="lg"
            variant="fill"
            shape="round"
            name="search"
            placeholder={`Search`}
    
            value={searchBarValue}
            onChange={(e: string) => setSearchBarValue(e)}
            prefix={
              <Img
                src="images/img_search.svg"
                alt="search"
                className="cursor-pointer"
              />
            }
            suffix={
              searchBarValue?.length > 0 ? (
                <CloseSVG
                  onClick={() => setSearchBarValue("")}
                  fillColor="#667084ff"
                />
              ) : null
            }
            className="h-12 p-4"
          />
        </div>
        <div className="sm:w-full w-5/12 flex gap-2">
        {/* <div className="w-2/4 flex justify-evenly items-center rounded-md ">
             <Button 
             color="blue_gray_800_02"
            size="lg"
            variant="fill"
            shape="round"
            className=" font-bold p-6" onClick={()=>setIsEntityModal(true)}>Add Entity</Button>
          </div> */}
          <div className="w-2/4 flex justify-evenly items-center rounded-md border-[1.5px] border-gray-100 ">
            <Img src="images/exportIcon.png" className="w-6" /> <p>Export</p>
          </div>
          <div className="w-2/4 flex justify-evenly items-center rounded-md border-[1.5px] border-gray-100 ">
            <Img src="images/filter.png" className="w-6" /> <p>Filter By</p>
          </div>
        </div>
      </div>
      <div className="overflow-x-auto w-full border-[1.4px] border-gray-100 rounded-sm">
        <table className="w-full bg-white">
          <thead>
            <tr>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                <input type="checkbox" className="rounded-md" />
              </th> 
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Entity
              </th>
              <th className="px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                {" "}
                Company
              </th>
              <th className="px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                {" "}
                Monthly Issue
              </th>
              <th className="px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Percentage(%)
              </th>
              <th className="px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Status
              </th>
              <th className="px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Statistics
              </th>
              <th className="px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                action
              </th>
            </tr>
          </thead>
          <tbody>
            {[1, 2, 3, 4,5,6,7,8,9].map((item, pos) => (
              <tr key={pos}>
                <td className="pl-6 py-4 whitespace-no-wrap border-b border-gray-200">
                  <input type="checkbox" name="organ" className="rounded-md" />
                </td>
                <td className="pr-2 py-4 whitespace-no-wrap border-b border-gray-200">
                  {" "}
                  <div className="flex justify-center items-center w-[72%] gap-2.5">
                    <div className="h-[30px] w-[50px]">
                      <Img
                        src="images/lady.png"
                        alt="image_one"
                        // className="h-[30px] w-[50px] m-auto rounded-[50%] "
                      />
                    </div>
                    <div className="flex flex-col self-start items-start ">
                      <Heading size="xs" as="p" className="!text-gray-9text1">
                        Tanner Finsha
                      </Heading>
                      <p className="!text-blue_gray-600">
                        @Tannerfisher@gmail.com
                      </p>
                    </div>
                  </div>
                </td>
                <td className="px-2 py-4 whitespace-no-wrap border-b border-gray-200">
                  20
                </td>
                <td className="px-2 py-2 whitespace-no-wrap border-b border-gray-200">
                <Img src="images/slider.png" className="w-14" />
                </td>
          
                <td className="px-2 py-2 whitespace-no-wrap border-b border-gray-200">
                  20%
                </td>
                <td className="px-2 py-4 whitespace-no-wrap border-b border-gray-200">
                  {pos === 1 ? (
                    <Img src="images/inactive.png" className="w-14" />
                  ) : (
                    <Img src="images/active.png" className="w-14" />
                  )}
                </td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                {pos%2 === 1 ? (
                    <Img src="images/decrease.png" className="w-14" />
                  ) : (
                    <Img src="images/increase.png" className="w-14" />
                  )}
                </td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 cursor-pointer" onClick={()=>setView(true)}>
                  View
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      

        {/* <ReactPaginate
        previousLabel={'Previous'}
        nextLabel={'Next'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={Math.ceil(data.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={'pagination'}
        activeClassName={'active'}
      /> */}
      </div>
      <div className="sticky border-b border-gray-200 w-full flex justify-evenly h-16">
          {/* <div className="flex bg-red-400 w-[100%]"> */}
            <div className="w-auto flex justify-evenly items-center border-gray-100 gap-4">
              <Img src="images/previous.png" className="w-20" /> 
            </div>

            <div className="w-3/4 flex justify-center items-center border-gray-100 ">
              {[1,2,3,4,5,6,7,8,9].map((item:number, pos:number)=>{
                return(
                    <span key={item} className="border-[1.2px] p-2 rounded-md hover:bg-gray-100 m-2">{pos+1}</span>
                    )
              })}
            </div>

            <div className="w-auto flex justify-evenly items-center border-gray-100 gap-2">
              <Img src="images/next.png" className="w-20" /> 
            </div>
        </div>
    </>
  );
};

export default Operation;
  