import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import CreateModal from "modals/customModal";
import CustomInput from "components/customizedInput";
import { Button } from "components/Button";
import { ApiService } from "axiosRequest";
import { toast } from "react-toastify";


interface Props {
  className?: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;

}

const AddOrganization = ({ isOpen, setIsOpen, ...props }: Props) => {

 

  const formik = useFormik({
    initialValues: {
        nb_employees:  "",
        organzation_name:"",
        activity:  "",
        CO2_emission:  "",
        city:  "",
        nb_entities:"",
        country: "",
        start_date:"",
        end_date:""
    },
    validationSchema: yup.object().shape({
        organization_name:yup.string().required(),
    nb_employees: yup.string().required(),
    CO2_emission: yup.number().required().positive().integer(),
      activity: yup.string().required(),
      country: yup.string().required(),
      city: yup.string().required(),
      nb_entities: yup.number().required().positive().integer(),
      start_date:yup.string().required(),
      end_date:yup.string().required(),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      console.log(values)
      const res=   await new ApiService().addOrganization(values)
      if(!res.error){
      setIsOpen(false)
      toast.success("Organization added successfully");
      }
    },
  });

  const fields = [
    {
        type: "text",
        placeholder: "Enter organization name ",
        label: "Organization Name",
        name: "organization_name",
      },
    {
      type: "number",
      placeholder: "Enter no of employees ",
      label: "Number of Employee",
      name: "nb_employees",
    },
    {
        type: "number",
        placeholder: `Enter No of Entity `,
        label: "Entities",
        name: "nb_entities",
      },
    {
        type: "number",
        placeholder: `Enter CO2 `,
        label: "Number of CO2",
        name: "CO2_emission",
      },
      {
        type: "text",
        placeholder: `Enter Country `,
        label: "Country",
        name: "country",
      },
      {
        type: "text",
        placeholder: `Enter City `,
        label: "City",
        name: "city",
      },
      {
        type: "text",
        placeholder: `Enter Activity`,
        label: "Activity",
        name: "activity",
      },
      {
        type: "date",
        placeholder: `Enter Start Date`,
        label: "Start Date",
        name: "start_date",
      },
      {
        type: "date",
        placeholder: `Enter End Date`,
        label: "End Date",
        name: "end_date",
      },
    
  ];

  return (
    <CreateModal title="Add Organization" isOpen={isOpen} setIsOpen={setIsOpen} {...props}>
      <form onSubmit={formik.handleSubmit} className="mt-2"> 
        {fields.map((field) => (
         <div className="mt-2">
          <CustomInput
            key={field.name}
            placeholder={field.placeholder}
            name={field.name}
            value={formik.values[field.name]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[field.name] && formik.errors[field.name]}
            type={field.type}

          />
         </div>
        ))}

        <Button
          color="blue_gray_800_02"
          size="4xl"
          variant="fill"
          shape="round"
          className="sm:px-5 font-bold min-w-[100%] self-center mt-4"
          disabled={formik.isSubmitting}
          type="submit"
        >
          {formik.isSubmitting ? "Saving..." : "Save Information"}
        </Button>
      </form>
    </CreateModal>
  );
};

export default AddOrganization;







