import React from "react";

const sizes = {
  xs: "text-[10px] font-medium leading-[14px]",
  lg: "text-base font-medium leading-[21px]",
  s: "text-xs font-medium leading-4",
  md: "text-sm font-medium leading-[18px]",
};

export type TextProps = Partial<{
  className: string;
  as: any;
  size: keyof typeof sizes;
}> &
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;

const Text: React.FC<React.PropsWithChildren<TextProps>> = ({
  children,
  className = "",
  as,
  size = "md",
  ...restProps
}) => {
  const Component = as || "p";

  return (
    <Component className={`text-blue_gray-500_02 font-mulish ${className} ${sizes[size]}`} {...restProps}>
      {children}
    </Component>
  );
};

export { Text };
